import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from '../components/navbar';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Suppr from './supp.png'
import Loader from "../components/loader";
import Footer from '../components/footer';
import moni from '../components/image/moni.png';
import secre from '../components/image/images.png'
import { API_BASE_URL } from '../apiConfig';
export default function VoirCode(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto-d4cc8.web.app',
        'credentials': 'include',
      };
    const [candidat, setCandidat] = useState([])
    const [presence,setPresence]= useState([])
    const [id_cand, setId_cand]= useState([])
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [mie, setMie]= useState(Cookies.get('mie'))
    const [error, setError] = useState(null);
    const [loader, setLoader]= useState(true)
    const [viewLoader, setViewLoader] = useState(false)
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    useEffect(()=>{
        axios.get(API_BASE_URL +'/condCode?id='+mie+'', { headers})
        .then(res => setCandidat(res.data))
        .catch(err => console.log(err));
    }, [])
          
    
    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        
     
        axios.get(API_BASE_URL +'/moniInfo?id='+gestauto+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
       
    
    
     const [id_ecole]= secretaire.map((data)=>(data.id_ecole));
  

     const [query, setQuery]= useState(""); 

         let exp

         var date= new Date();
         const now= date.toLocaleDateString();
        const years= date.getFullYear()
       const  month= date.getMonth()+1
        const day= date.getDate()
      
        const mettreAJourLargeurEcran = () => {
            setLargeurEcran(window.innerWidth);
        
          };
        
           
           
            
            
            useEffect(() => {      
                window.addEventListener('resize', mettreAJourLargeurEcran);      
                return () => {
                  window.removeEventListener('resize', mettreAJourLargeurEcran);        
                };      
              }, []);   
        
         useEffect(()=>{
            axios.post(API_BASE_URL +'/voirCode', {years,month,day,mie}, { headers})
            .then(res => {
               setPresence(res.data)
               setLoader(false)
                
            }).catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                
                });
        }, [])          
            
            
            useEffect(()=>{
                axios.post(API_BASE_URL +'/date', {years,month,day,mie}, { headers})
                .then(res => {
                   setId_cand(res.data)
         
                    
                    
                }).catch(err => console.log(err));
            }, [])

            const handleDelete = async(id) => {  
                try {
                 await axios.delete(API_BASE_URL +'/absentCode/'+id, { headers})
                 window.location.reload()
                }catch(err) {
                 console.log(err);
                }
              }

              const[valid, setValid]= useState('')
              
              const[valid2, setValid2]= useState('')
              const[supMod, setSupMod]= useState(false) 

              const toggleSup = ()=> {
                setValid2(valid)
               
                setSupMod(!supMod)
                setValid('')
            }


            if(valid!= ''){
               toggleSup()
               
              
              
              } 


              let load

              if(viewLoader==true){
                  load=   <div className="avt">
                  <div className="rin">
                    <div className="chm">
                      <Loader/>
                    </div>
                  </div>
                </div>
              }else{
                  load=''
              }
  
  
              if(loader){
                  return(
                      <div className="per">
                      
                      <div className="loader">
                      <div className="overlayLo"> 
                          <div className="loaderP">
                          <Loader/>
                          </div>
                          </div>
                          </div>
                          </div>
                  )
              }else if(error){
                  return (<div>Une erreur est survenue : {error.message}</div>)
              }else{
                if(largeurEcran>= 510){  
            return(
                <>
                
                <div className='wini'>
                <Navbar/>
                <h1 className='win'>Les presents du cour de code du {now}</h1><br/><br/>
                
                <div className="search">
                     <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                </div>
                <div className="searche_result">
                    
                   
                </div>
                <br/><br/>
               
            <div className=''>
                <div className=''>
                    
                <div className="card-list"  >
                                 {
                                            presence.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                                                <div className="card" >
                                                          <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div> 
                                             
                                             <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                <p className='contacts'> {data.contact}</p>
                                               
                                                <i className="fa-solid fa-trash-can modaFF" onClick={e => setValid(data.id)}></i>
                                              
                                        {supMod && (<div className='modalSup'>
    <div className='overlaySup'>
        
    </div>
    <div className='modal-contentSup'>
        <h2>Voulez-vous vraiment supprimer ?</h2>
     
      <div className='supD'>
      <Link onClick={e => handleDelete(valid2)}> <button className='supC' >Confirmer</button> </Link>
      <Link onClick={toggleSup}><button className='supA' > Annuler </button></Link>
      </div>
     
      
    </div>
   
</div>)}
                               
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                                 
                </div>
                
                <Link to={'/ajoutCode'} > <button className='ess'>Ajouter à la liste</button></Link>
            </div>
            <br/>
            </div>
            <Footer/>
            </>
            )
                    
                            }else{
                                return(
                                    <>
                                    
                                    <div className='wini'>
                                    <Navbar/>
                                    <h1 className='win'>Les presents du cour de code du {now}</h1><br/><br/>
                                    
                                    <div className="search">
                                         <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                                    </div>
                                    <div className="searche_result">
                                        
                                       
                                    </div>
                                    <br/><br/>
                                   
                                <div className=''>

                                <div className="card-list"  >
                                 {
                                            presence.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                                                <div className="card" >
                                                          <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                                          
                                          
                                             <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                <p className='contacts'> {data.contact}</p>
                                               
                                                <i className="fa-solid fa-trash-can modaFF" onClick={e => setValid(data.id)}></i>
                                              
                                        {supMod && (<div className='modalSup'>
    <div className='overlaySup'>
        
    </div>
    <div className='modal-contentSup'>
        <h2>Voulez-vous vraiment supprimer ?</h2>
     
      <div className='supD'>
      <Link onClick={e => handleDelete(valid2)}> <button className='supC' >Confirmer</button> </Link>
      <Link onClick={toggleSup}><button className='supA' > Annuler </button></Link>
      </div>
     
      
    </div>
   
</div>)}
                               
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                                 

                                    <Link to={'/ajoutCode'} > <button className='ess'>Ajouter à la liste</button></Link>
                                </div>
                                <br/>
                                </div>
                                <Footer/>
                                </>
                                )
                                        
                                  
                            }
                              }
                            }