import React, {useEffect, useState} from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link,NavLink, useNavigate,  useLocation } from "react-router-dom";
import { sidedata } from "./sidedata";
import { IconContext } from "react-icons";
import images from './image/images.png';
import moni from './image/moni.png';
import { sidedataMoni } from "./sidedataMoni";
import log from "./image/log.png"
import Cookies from 'js-cookie';
import axios from 'axios';
import Loader from "./loader";
import logo from './image/logo4.jpg'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { API_BASE_URL } from '../apiConfig';
export default function Navbar(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
   const navigate= useNavigate()
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const[profil, setProfil]= useState(false)
    const showProfil = () => setProfil(!profil)
    const [secretaire, setSecretaire] = useState([])
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState(Cookies.get('token'))
    const [titre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [mie, setMie]= useState(Cookies.get('mie'))
    const [certain, setCertain]= useState('')
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const upTok =()=>{
         setLu(Cookies.get('token'))

        
    }
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };


      const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')



        useEffect(()=>{
        
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
    
    useEffect(()=>{
        if(!Cookies.get('token')){
            Cookies.remove('mie')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
           navigate('/')
        }
   }, [])

   const logout = () =>{
   
    Cookies.remove('token')
    Cookies.remove('mie')
    Cookies.remove('titre')
    Cookies.remove('gestauto')
    Cookies.remove('default')
    Cookies.remove('default2')
    Cookies.remove('defaultCode')
    Cookies.remove('defaultCode2')
    Cookies.remove('select1')
        Cookies.remove('select2')
   navigate('/')
   window.location.reload();
}

if(tok!= lu){
    Cookies.remove('token')
    Cookies.remove('mie')
    Cookies.remove('titre')
    Cookies.remove('gestauto')
    window.location.reload();
    navigate('/')

}

useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    axios.post(API_BASE_URL +'/setToken' ,{gestauto,titre}, { headers })
    .then(res => 
        {
            
            setTok(res.data[0].token)
            

        })
    .catch(err => console.log(err));
}, [])


useEffect(()=>{
    axios.post(API_BASE_URL +'/cer' ,{gestauto,titre,mie,tok}, { headers })
    .then(res => 
        {
            setCertain(res.data.message);

        })
    .catch(err => console.log(err));
}, [])

    if(certain=='denied'){
        Cookies.remove('token')
        Cookies.remove('mie')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        window.location.reload();
        navigate('/')
    }
         var controleAnnexe= 1;  


//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put(API_BASE_URL +'/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                    
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}






const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};



const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);




useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);


const location = useLocation();






















const [IDV, setIDV]=useState('')

const [viewLoader, setViewLoader] = useState(false)

const[photo, setPhoto]= useState('')
              
const[ph, setPh]= useState(false) 

const toggleInscritUser = ()=> {

    setPhoto('')
    setPh(!ph)
 
}


if(photo!== ''){
 setIDV(photo)
 toggleInscritUser()
 
} 






function handleImportClickUser() {
    const fileInput = document.getElementById(`file-input-${IDV}`);
    if (fileInput) {
      fileInput.click();
    } else {
      console.error(`File input with id 'file-input-${IDV}' not found`);
    }
  }
  
  function handleImageUploadUser(event, id) {
  
    const file = event.target.files[0];
    if (file) {
        if(sidebar){
            showSidebar()
        }
        
      setViewLoader(true)
      const formData = new FormData();
      formData.append('image', file);
      formData.append('id', id);
      toggleInscritUser()
      axios.post(API_BASE_URL +'/upload-image-secre', formData)
        .then(response => {
          window.location.reload(); // Recharger la page après l'upload
        })
        .catch(error => {
          console.error("Erreur lors de l'upload de l'image:", error);
        });
    }
  }




 
  function handleImageUploadUserMoni(event, id) {
  
    const file = event.target.files[0];
    if (file) {
        if(sidebar){
            showSidebar()
        }
        
      setViewLoader(true)
      const formData = new FormData();
      formData.append('image', file);
      formData.append('id', id);
      toggleInscritUser()
      axios.post(API_BASE_URL +'/upload-image-moni', formData)
        .then(response => {
          window.location.reload(); // Recharger la page après l'upload
        })
        .catch(error => {
          console.error("Erreur lors de l'upload de l'image:", error);
        });
    }
  }




  function openCameraUser() {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        video.srcObject = stream;
        video.play();
  
        // Créer une modal personnalisée
        const modal = document.createElement('div');
        modal.className = 'camera-modal-custom';
        document.body.appendChild(modal);
        modal.appendChild(video);
  
        // Bouton pour capturer l'image
        const captureButton = document.createElement('button');
        captureButton.className = 'bouton-camera-capture';
        captureButton.innerHTML = '<i class="fas fa-camera"></i>'; // Icône de capture
        modal.appendChild(captureButton);
  
        // Bouton pour annuler
        const cancelButton = document.createElement('button');
        cancelButton.className = 'bouton-camera-annuler';
        cancelButton.innerText = 'Annuler';
        modal.appendChild(cancelButton);
  
        // Centrer l'icône de la caméra
        video.style.display = 'block';
        video.style.margin = '0 auto';
  
        // Capturer l'image
        captureButton.addEventListener('click', () => {
          canvas.width = 300;
          canvas.height = 300;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
          video.style.display = 'none';
          modal.appendChild(canvas);
  
             
  
          // Bouton pour valider l'image
          const confirmButton = document.createElement('button');
          confirmButton.className = 'bouton-camera-confirmer';
          confirmButton.innerHTML = '<i class="fas fa-check"></i>';
          modal.appendChild(confirmButton);
  
          // Bouton pour reprendre la capture
          const retryButton = document.createElement('button');
          retryButton.className = 'bouton-camera-reprendre';
          retryButton.innerHTML = '<i class="fas fa-redo"></i>';
          modal.appendChild(retryButton);
  
          // Valider et envoyer l'image
          confirmButton.addEventListener('click', () => {
            canvas.toBlob(blob => {
              if (blob) {
                const formData = new FormData();
                formData.append('image', blob, 'profile-image.jpg');
                formData.append('id', IDV);
  toggleInscritUser()
  setViewLoader(true)
                axios.post(API_BASE_URL +'/upload-image-secre', formData)
                  .then(() => {
                        
  
               
                   setViewLoader(false) 
                  window.location.reload()
                  
                  })
                  .catch(error => console.error('Erreur lors de l\'upload:', error));
  
                video.srcObject.getTracks().forEach(track => track.stop());
                modal.remove();
              }
            }, 'image/jpeg');
          });
  
          // Masquer le bouton "Annuler" après capture
          cancelButton.style.display = 'none';
  
          // Reprendre la capture
          retryButton.addEventListener('click', () => {
            confirmButton.remove();
            retryButton.remove();
            canvas.remove();
             
            video.style.display = 'block';
            cancelButton.style.display = 'block'; // Réafficher le bouton "Annuler"
            captureButton.style.display = 'block'; // Faire réapparaître l'icône caméra
          });
  
          captureButton.style.display = 'none'; // Masquer l'icône caméra après capture
        });
  
        cancelButton.addEventListener('click', () => {
          video.srcObject.getTracks().forEach(track => track.stop());
          modal.remove();
        });
      })
      .catch(error => console.error('Erreur lors de l\'accès à la caméra:', error));
  }




  function openCameraUserMoni() {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        video.srcObject = stream;
        video.play();
  
        // Créer une modal personnalisée
        const modal = document.createElement('div');
        modal.className = 'camera-modal-custom';
        document.body.appendChild(modal);
        modal.appendChild(video);
  
        // Bouton pour capturer l'image
        const captureButton = document.createElement('button');
        captureButton.className = 'bouton-camera-capture';
        captureButton.innerHTML = '<i class="fas fa-camera"></i>'; // Icône de capture
        modal.appendChild(captureButton);
  
        // Bouton pour annuler
        const cancelButton = document.createElement('button');
        cancelButton.className = 'bouton-camera-annuler';
        cancelButton.innerText = 'Annuler';
        modal.appendChild(cancelButton);
  
        // Centrer l'icône de la caméra
        video.style.display = 'block';
        video.style.margin = '0 auto';
  
        // Capturer l'image
        captureButton.addEventListener('click', () => {
          canvas.width = 300;
          canvas.height = 300;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
          video.style.display = 'none';
          modal.appendChild(canvas);
  
             
  
          // Bouton pour valider l'image
          const confirmButton = document.createElement('button');
          confirmButton.className = 'bouton-camera-confirmer';
          confirmButton.innerHTML = '<i class="fas fa-check"></i>';
          modal.appendChild(confirmButton);
  
          // Bouton pour reprendre la capture
          const retryButton = document.createElement('button');
          retryButton.className = 'bouton-camera-reprendre';
          retryButton.innerHTML = '<i class="fas fa-redo"></i>';
          modal.appendChild(retryButton);
  
          // Valider et envoyer l'image
          confirmButton.addEventListener('click', () => {
            canvas.toBlob(blob => {
              if (blob) {
                const formData = new FormData();
                formData.append('image', blob, 'profile-image.jpg');
                formData.append('id', IDV);
  toggleInscritUser()
  setViewLoader(true)
                axios.post(API_BASE_URL +'/upload-image-moni', formData)
                  .then(() => {
                        
  
               
                   setViewLoader(false) 
                  window.location.reload()
                  
                  })
                  .catch(error => console.error('Erreur lors de l\'upload:', error));
  
                video.srcObject.getTracks().forEach(track => track.stop());
                modal.remove();
              }
            }, 'image/jpeg');
          });
  
          // Masquer le bouton "Annuler" après capture
          cancelButton.style.display = 'none';
  
          // Reprendre la capture
          retryButton.addEventListener('click', () => {
            confirmButton.remove();
            retryButton.remove();
            canvas.remove();
             
            video.style.display = 'block';
            cancelButton.style.display = 'block'; // Réafficher le bouton "Annuler"
            captureButton.style.display = 'block'; // Faire réapparaître l'icône caméra
          });
  
          captureButton.style.display = 'none'; // Masquer l'icône caméra après capture
        });
  
        cancelButton.addEventListener('click', () => {
          video.srcObject.getTracks().forEach(track => track.stop());
          modal.remove();
        });
      })
      .catch(error => console.error('Erreur lors de l\'accès à la caméra:', error));
  }






// Détecter si l'utilisateur est sur iOS
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);


function openCameraUserIOS2(id) {
    if (isIOS) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
      fileInput.style.display = 'none'; // Cacher l'input file visuellement
  
      // Gérer la sélection d'une image via l'appareil photo
      fileInput.onchange = function(event) {
        const file = event.target.files[0];
        if (file) {
          if(photo){
            toggleInscritUser()
           }
       showSidebar()
          setViewLoader(true)

          const formData = new FormData();
          formData.append('image', file, 'profile-image.jpg');
          formData.append('id', id); // Remplacez par votre ID de candidat
  
          axios.post(API_BASE_URL +'/upload-image-secre', formData)
            .then(response => {
              setTimeout(() => {
              
                console.log('Image uploadée avec succès');
                window.location.reload(); // Recharger la page après 3 secondes
              }, 200);
            
            })
            .catch(error => console.error('Erreur lors de l\'upload:', error));
        }
      };
  
      // Ajouter le champ input au document et le déclencher
      document.body.appendChild(fileInput);
      fileInput.click(); // Simuler un clic pour ouvrir la caméra
    }
  }




  function openCameraUserMoniIOS2(id) {
    if (isIOS) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
      fileInput.style.display = 'none'; // Cacher l'input file visuellement
  
      // Gérer la sélection d'une image via l'appareil photo
      fileInput.onchange = function(event) {
        const file = event.target.files[0];
        if (file) {
          if(photo){
            toggleInscritUser()
           }
       showSidebar()
          setViewLoader(true)

          const formData = new FormData();
          formData.append('image', file, 'profile-image.jpg');
          formData.append('id', id); // Remplacez par votre ID de candidat
  
          axios.post(API_BASE_URL +'/upload-image-moni', formData)
            .then(response => {
              setTimeout(() => {
              
                console.log('Image uploadée avec succès');
                window.location.reload(); // Recharger la page après 3 secondes
              }, 200);
            
            })
            .catch(error => console.error('Erreur lors de l\'upload:', error));
        }
      };
  
      // Ajouter le champ input au document et le déclencher
      document.body.appendChild(fileInput);
      fileInput.click(); // Simuler un clic pour ouvrir la caméra
    }
  }





   // Pour stocker l'ID du champ de fichier



  function handleImportUserClick2(id) {

    if (isIOS) {
      openCameraUserIOS2(id); // Ouvrir l'appareil photo pour iOS après la confirmation
    } else {
    
      const fileInput = document.getElementById(`file-input-${id}`);
      if (fileInput) {
        fileInput.click(); // Ouvrir le gestionnaire de fichiers pour les autres plateformes
      } else {
        console.error(`File input with id 'file-input-${id}' not found`);
      }
    }

  
  }



  function handleImportUserClick2(id) {

    if (isIOS) {
      openCameraUserIOS2(id); // Ouvrir l'appareil photo pour iOS après la confirmation
    } else {
    
      const fileInput = document.getElementById(`file-input-${id}`);
      if (fileInput) {
        fileInput.click(); // Ouvrir le gestionnaire de fichiers pour les autres plateformes
      } else {
        console.error(`File input with id 'file-input-${id}' not found`);
      }
    }

  
  }

  
  function handleImportUserMoniClick2(id) {

    if (isIOS) {
      openCameraUserMoniIOS2(id); // Ouvrir l'appareil photo pour iOS après la confirmation
    } else {
    
      const fileInput = document.getElementById(`file-input-${id}`);
      if (fileInput) {
        fileInput.click(); // Ouvrir le gestionnaire de fichiers pour les autres plateformes
      } else {
        console.error(`File input with id 'file-input-${id}' not found`);
      }
    }

  
  }

  let load

  if(viewLoader==true){
      load=   <div className="avt">
      <div className="rin">
        <div className="chm">
          <Loader/>
        </div>
      </div>
    </div>
  }else{
      load=''
  }














      if(secretaire.map((data)=>(data.poste))=="secretaire"){
      
       if(largeurEcran>= 1200){

       
        return(
        
            <>
            
            <IconContext.Provider value={{color:'#fff'}}>
            <div className="barHaut">


            <div className="lep">
                
                
             {/* <img src={images} className="user" onClick={showProfil} /> */}



             <img src={secretaire[0].image ? secretaire[0].image  : images} alt="Candidat" className="user" />


{load}


             {ph && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClickUser()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCameraUser()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscritUser}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 

  <input
  type="file"
  id={`file-input-${secretaire[0].id}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, secretaire[0].id)}
/>




             {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
             
             <div className="hero"> 
             <h2 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>
             <h5 className="post"> SECRETAIRE  {secretaire.map((data)=>(data.auto_ecole))}</h5>
             </div>

             <div class="options">




             {!secretaire[0].image && (
        <h2 className="form-title2"> 
        {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}
        </h2>
      )}
        
        <h2 className="form-title2"> 
     {secretaire[0].image && (
        <PhotoProvider>
          <PhotoView src={secretaire[0].image}>
          <button class="option-btn10" >Voir profile</button>
          </PhotoView>
        </PhotoProvider>
      )}
        
        </h2>   



     <button class="option-btn" onClick={e => setValid('on')}>Modifier mot de passe</button>
    <button class="option-btn2" onClick={   e => setPhoto(secretaire[0].id)}>Modifier photo de profile</button>

 </div>


             </div>
  


              
            <NavLink to={'/accueil'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} } >   <button  className="pr"> Accueil </button></NavLink>
                <NavLink to={'/candidat'}  style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }   ><button className='pr'> Candidats </button></NavLink>
                <NavLink to={'/depense'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }   ><button className='pr'> Depenses </button></NavLink>
                <NavLink to={'/payement'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }   ><button className='pr'> Recettes </button></NavLink>
                <NavLink to={'/resultat'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }  ><button className='pr'> Resultats </button></NavLink>
                <Link onClick={logout} className="link">
                           <img src={log} className="am" />
                           <div className="deco">DECONNEXION</div>
                           
                      </Link>
                
    
            </div>
            </IconContext.Provider>
    
            
            </>
        )
       }else{

        //ici navOLd
        return(
        
            <>
            
            <IconContext.Provider value={{color:'#fff'}} >
            
                <div className="icon">
             <div>
             &nbsp;&nbsp;&nbsp;
             <img src={logo} className="userLog"  /> 
             </div>
             <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div> 
             {load}     
            <div className="navbar">
            
                <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                    <FaIcons.FaBars onClick={showSidebar} />
                </Link>
          
                &nbsp;&nbsp;&nbsp;
                  
    
            </div>
           
            
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className="nav-menu-items" >
                    <li className="navbar-toggle">
                        <Link to="#" className="menu-bars">
                           <AiIcons.AiOutlineClose onClick={showSidebar}/>
                        </Link>
                    </li>
                    <div className="carD" >
                    <div className="profil">


       {/*  <AiIcons.AiOutlineUser className="icone-profil" onClick={toggleMenu} /> */}
      {secretaire[0].image ? (
  <img
    src={secretaire[0].image}
    alt="Profil"
    className="image-profilUser"
    onClick={toggleMenu}
  />
) : (
  <AiIcons.AiOutlineUser className="icone-profil" onClick={toggleMenu} />
)}

<input
  type="file"
  id={`file-input-${secretaire[0].id}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, secretaire[0].id)}
/>


        <div className="infos-utilisateur">
        <p className="inPo" onClick={toggleMenu} > {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN" onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
      
        </div>
      </div>

      {menuVisible && (


  <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier mot de passe</button>
               {/* Bouton pour voir la photo - s'affiche seulement si l'image existe */}
      {secretaire[0].image && (
        <PhotoProvider>
          <PhotoView src={secretaire[0].image}>
            <button className="option-btnMobi">Voir profile</button>
          </PhotoView>
        </PhotoProvider>
      )}
         <button className="option-btnMobi2" onClick={ () => isIOS ? handleImportUserClick2(secretaire[0].id) : handleImportUserClick2(secretaire[0].id) }>Modifier photo de profile</button>
      </div>

   )}
      
  
      </div>
                    {sidedata.map((item, index) =>{
                        const isActive = location.pathname === item.path;
                      return (
                       
                        <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                            <Link to={item.path}>
                                <span>{item.title}</span>
                             </Link>
                            
                        </li>
                        
                      
                      )

                    })}
                   <li className="nav-text">
                    <Link onClick={logout}  >
                         
                           <span>Deconnexion</span>
                           
                      </Link>
                      </li> 
                </ul>
            </nav>
            </div>
            </IconContext.Provider>
    

            {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 
    
            
            </>
        )
       }
    }else if(secretaire.map((data)=>(data.poste))=="moniteur") {
        if(largeurEcran>= 1200){
            return(
        
                <>
                
                
                <div className="barHaut">
    
                <div className="lep">
                <img src={secretaire[0].image ? secretaire[0].image  : moni} alt="profil" className="user" />
                {load}
                    
                  {/*   <img src={moni} className="user" onClick={showProfil} /> */}




                  {ph && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClickUser()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCameraUserMoni()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscritUser}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 

  <input
  type="file"
  id={`file-input-${secretaire[0].id}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUserMoni(event, secretaire[0].id)}
/>


                    {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                    <h2 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>
                    <h5 className="post"> MONITEUR  {secretaire.map((data)=>(data.auto_ecole))}</h5>
                    </div>
       

                    <div class="options">
       {!secretaire[0].image && (
        <h2 className="form-title2"> 
        {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}
        </h2>
      )}
        
        <h2 className="form-title2"> 
     {secretaire[0].image && (
        <PhotoProvider>
          <PhotoView src={secretaire[0].image}>
          <button class="option-btn10" >Voir profile</button>
          </PhotoView>
        </PhotoProvider>
      )}
        
        </h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier mot de passe</button>
    <button class="option-btn2" onClick={   e => setPhoto(secretaire[0].id)}>Modifier photo de profile</button>
    </div>
       

                    </div>
                    
              
                <NavLink to={'/accueil'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className="pr"> Accueil</button> </NavLink>
                <NavLink to={'/candidatMoni'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className="pr"> Candidats </button></NavLink>
                <NavLink to={'/presence'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }> <button className="pr">Presence </button></NavLink>
                    
                    <NavLink to={'/moni'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className="pr"> Resultats</button> </NavLink>
                    
                    <Link onClick={logout} className="link">
                               <img src={log} className="am" />
                               <div className="deco">DECONNEXION</div>
                               
                          </Link>
        
                </div>
               
                
                </>
            )
        }else{
            
            return(
        
                <>
                
                <IconContext.Provider value={{color:'#fff'}} >
                
                    <div className="icon">
                 <div>
                 &nbsp;&nbsp;&nbsp;
                 <img src={logo} className="userLog"  /> 
                 </div>
                       
                 <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>
             
             {load}
                <div className="navbar">
                
                    <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                        <FaIcons.FaBars onClick={showSidebar} />
                    </Link>
              
                    &nbsp;&nbsp;&nbsp;
                      
        
                </div>
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className="nav-menu-items" >
                        <li className="navbar-toggle">
                            <Link to="#" className="menu-bars">
                               <AiIcons.AiOutlineClose onClick={showSidebar}/>
                            </Link>
                        </li>
                        <div className="carD">
                    <div className="profil">


       {/*  <AiIcons.AiOutlineUser className="icone-profil" onClick={toggleMenu}/> */}

       {secretaire[0].image ? (
  <img
    src={secretaire[0].image}
    alt="Profil"
    className="image-profilUser"
    onClick={toggleMenu}
  />
) : (
  <AiIcons.AiOutlineUser className="icone-profil" onClick={toggleMenu} />
)}

<input
  type="file"
  id={`file-input-${secretaire[0].id}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUserMoni(event, secretaire[0].id)}
/>



        <div className="infos-utilisateur">
        <p className="inPo" onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN" onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
      
        </div>
      </div>

      {menuVisible && (
     <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier mot de passe</button>
         {secretaire[0].image && (
        <PhotoProvider>
          <PhotoView src={secretaire[0].image}>
            <button className="option-btnMobi">Voir profile</button>
          </PhotoView>
        </PhotoProvider>
      )}
         <button className="option-btnMobi2" onClick={ () => isIOS ? handleImportUserMoniClick2(secretaire[0].id) : handleImportUserMoniClick2(secretaire[0].id) }>Modifier photo de profile</button>
      </div>
   )}
      

      </div>
                        {sidedataMoni.map((item, index) =>{
                            const isActive = location.pathname === item.path;
                          return (
                           
                          <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                               <Link to={item.path}>
                                    <span>{item.title}</span>
                                 </Link>
                                
                            </li>
                            
                          
                          )
    
                        })}
                       <li className="nav-text">
                        <Link onClick={logout}  >
                             
                               <span>Deconnexion</span>
                               
                          </Link>
                          </li> 
                    </ul>
                </nav>


                </div>
                </IconContext.Provider>
        

                {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 
        
                
                </>
            )

        }
       
    }else if(secretaire.map((data)=>(data.poste))=="directeur"){



        return(
        
            <>
            
            
            <div className="barHaut">

            <Link to={'/accueil'} className="lin"> Accueil </Link>
            <Link to={'/candidatDir'} className="lin"> Candidats </Link>
                <Link to={'/depenseDir'} className="lin"> Depenses </Link>
                <Link to={'/depense2Dir'} className="lin"> Mes depenses </Link>
                
                <Link to={'/payementDir'} className="lin"> Recettes </Link>
                <Link to={'/bilan'} className="lin"> Bilan Global </Link>
                <Link to={'/resultatDir'} className="lin"> Resultats </Link>
                
                
                <div className="lep">
                    <div className="hero">
                <h2 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>
                <h5> DIRECTEUR  {secretaire.map((data)=>(data.auto_ecole))}</h5>
                </div>
                <img src={moni} className="user" onClick={showProfil} />
                
                </div>
                <div className={profil ? 'sub-menu-wrap open':'sub-menu-wrap' } id="subMenu">
                    <div className="sub-menu">
                        <div className="user-info">
                            <img src={moni} className="if"></img>
                            <h2>{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>
                        </div>
                        <hr></hr>
    
                         <Link to="/" className="link">
                              <img src={log} className="am" />
                              <p>DECONNEXION</p>
                              <span>{'>'}</span>
                         </Link>
    
                    </div>
                </div>     
    
            </div>

    
            
            </>
        )




    }else{
        return(
            <>
            
            
            <div className="barHaut">

            
                
                
                <div className="lep">
                    <div className="hero">
                 </div>
                 </div>
               
    
            </div>

    
            
            </>
        )
    }
      }
    