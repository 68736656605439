import Navbar from '../components/navbar';
import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import moment from "moment";
import { Link, NavLink, useParams, useNavigate } from "react-router-dom";
import moni from '../components/image/moni.png';
import log from '../components/image/log.png';
import Footer from '../components/footer';
import Cookies from 'js-cookie';
import { sidedataDir } from '../components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import Loader from "../components/loader";
import logo from '../components/image/logo4.jpg'
import { API_BASE_URL } from '../apiConfig';
export default function Depense2Dir(){
    const navigate = useNavigate();
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [vtoken, setVtoken]= useState(Cookies.get('token'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
const [ce,setCe]= useState(Cookies.get('autoa'))
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const upTok =()=>{
         setLu(Cookies.get('token'))
     
    }

    var autre= date.toISOString().split('T')[0]

    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            window.location.reload();
            navigate('/')

        }
    }

    
    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])


    
    
    const logout = () =>{
        Cookies.remove('token')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);

  
  
// Recuperer token
useEffect(()=>{
    

axios.post(API_BASE_URL +'/setToken', { gestauto, titre},{ headers})
    .then(res => 
        {setTok(res.data[0].token)
        

        })
    .catch(err => console.log(err));
}, [])
//nav en haut
    
    const[profil, setProfil]= useState(false)
    const [idAnnexe,setIdAnnexe]= useState('ANNEXE')
    const showProfil = () => setProfil(!profil)

    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
    
   

    
    const [lea,setLea]=useState([0])
    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  var autre= date.toISOString().split('T')[0] 
    const[value,setValue]= useState('')


    const [date1, setDate1]= useState('')
    const [date2, setDate2]= useState('')

    const [annexe, setAnnexe]= useState('')
    

    const page="candidatDir";

    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/candidatDir?id='+ce+'' , { headers})
        .then(res => setVille(res.data))
        .catch(err => console.log(err));
    }, [])

    const [id_annexe]= ville.map((data)=>(data.id_annexe));
    
     const [query, setQuery]= useState(""); 

         
         const [depense, setDepense] = useState([])
         function handleSubmit(event){
            event.preventDefault();
            
            const [id_ecole]= ville.map((data)=>(data.id_ecole));

            
           
            axios.post(API_BASE_URL +'/candidatDir', {annexe,id_annexe,id_ecole}, { headers})
            .then(res => {
                
             
                const a= res.data[0].id_ecole
            
                setLea(res.data)

                axios.post(API_BASE_URL +'/infoDepense2', {a,month,years}, { headers})
                .then(res => setDepense(res.data)
      
                
                ).catch(err => console.log(err));
 
            }).catch(err => console.log(err));
         
        }
         
        const  le = lea.map((data)=>(data.id_ecole))
        var o=le
        const {id}= useParams();
        if (o!=0){
            o=le
        }else{
            
            o=id
        }

        useEffect(()=>{
            axios.post(API_BASE_URL +'/idAnnexe', {o}, { headers})
            .then(res =>{ 
                
                setIdAnnexe(res.data[0].ville)             
            }
               
    
            ).catch(err => console.log(err));
        }, [])
            var b
         
         b= idAnnexe

        useEffect(()=>{
            axios.post(API_BASE_URL +'/ticDep2', {o,month,years,le}, { headers})
            .then(res =>{ 
                
                setDepense(res.data)            
            }
               

            ).catch(err => console.log(err));
        }, [])


        useEffect(()=>{
            setDate1(autre)
            setDate2(autre)
        }, [])

        function handleTout(event){
            event.preventDefault();
            if(value=='Enregistré ce mois'){
                axios.post(API_BASE_URL +'/depenseInfoThis2',{month,years,le,o}, { headers})
                .then(res => setDepense(res.data))
                .catch(err => console.log(err));
                }
            else if(value=='Enregistré cette année'){
                axios.post(API_BASE_URL +'/depenseInfoThisYears2',{years,le,o}, { headers})
                .then(res => setDepense(res.data))
                .catch(err => console.log(err));
            }else if(value=='Tous les Depenses'){
                axios.post(API_BASE_URL +'/depInfo2',{le,o}, { headers})
                .then(res => setDepense(res.data))
                .catch(err => console.log(err));
            }
           
        }

        function handlePropose(event){
            event.preventDefault();
            axios.post(API_BASE_URL +'/personDepense2Dir', {date1,date2,le,o}, { headers})
            .then(res => {
               setDepense(res.data)
                console.log(res);
                
                
            }).catch(err => console.log(err));
        }
        // ici bar






        
        var bar 
        if(largeurEcran>= 1200){
            bar=  <div className="barHaut">

<div className="lep">
                    
                    
                    <img src={moni} className="user" onClick={showProfil} />
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    </div>


            <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
    <NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
        <NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
        <NavLink to={'/depense2Dir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Mes depenses</button> </NavLink>
        
        <NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
        <NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
        <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
        <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
        <NavLink to={'/employe/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Employés</button> </NavLink>
        
        <Link onClick={logout} className="link">
                               <img src={log} className="am" />
                               <div className="deco">DECONNEXION</div>
                               
                          </Link>  
    
    </div>
    
        }else{
            bar=  <IconContext.Provider value={{color:'#fff'}} >
                         
            <div className="icon">
         <div>
         &nbsp;&nbsp;&nbsp;
         <img src={logo} className="userLog"  /> 
         </div>
               
               
         <div className="navbar">
         
            <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                <FaIcons.FaBars onClick={showSidebar} />
            </Link>
         
            &nbsp;&nbsp;&nbsp;
              
         
         </div>
         
         <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className="nav-menu-items" onClick={showSidebar}>
                <li className="navbar-toggle">
                    <Link to="#" className="menu-bars">
                       <AiIcons.AiOutlineClose onClick={showSidebar}/>
                    </Link>
                </li>
                {sidedataDir.map((item, index) =>{
                  return (
                   
                    <li key={index} className={item.cName}>
                         <Link to={item.path+'/'+o}>
                            <span>{item.title}</span>
                         </Link>
                        
                    </li>
                    
                  
                  )
         
                })}
               <li className="nav-text">
                <Link onClick={logout}  >
                     
                       <span>Deconnexion</span>
                       
                  </Link>
                  </li> 
            </ul>
         </nav>
         </div>
         </IconContext.Provider>
                 
         }

    return(
        <>
            <div className='wini'>
        


      {bar}

       


        <h1 className='win'>AUTRE DEPENSE DE LA SOCIETE</h1>

        

           <div className='inove'>
            <div>
        <div className="search">
             <input type="text" className='recherche' name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value) }  ></input>
             </div>
             <br/>
             <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
           <input type='date' value={date1} className='oixe' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oixe' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='somi'>APPLIQUER</button>

           
           </div>

             </div>


        <div className='autre'  >
             
             <select name="poste" className='autreSel' onChange={e => setAnnexe(e.target.value)} onClick={handleSubmit}>
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option value={data.ville}>{data.ville}</option>
                            
                    ))
                    
                }
           </select>   
           <select name="poste" className='autreSel' onClick={handleTout} onChange={e => setValue(e.target.value)}>
           <option >Enregistré ce mois</option>
                   <option >Tous les Depenses</option>
            
            <option>Enregistré cette année</option>
            
           
            
                </select>
           </div>
           </div>
           
<Link to='/createDepenseDir' className='yello'> <button className='ajouter'>AJOUTER</button> </Link>    
           

        <div className=''>
        <div className=''>
             
             <table className='ta'>
                 <thead >
                    <tr>
                    
                    <th>Date</th>
                    <th>Libelle</th>
                    <th>Montant</th>
                    
                    </tr>
                 </thead>
                 <tbody>
                      {
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).map((data, i)=> (
                            <tr>
                                
                                <td className='tcond'>{moment(data.date).utc(1).format('DD-MM-YYYY')}</td>
                                <td className='tcond'>{data.libelle}</td>
                                <td className='tcond'>{data.montant} FCFA</td>
                                
                                
                            </tr>
                        ))
                      }
                 </tbody>
             </table>
        </div>
        
    </div>
    <br/>
    </div>
    <Footer/>
    </>

    )
}