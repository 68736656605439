import Navbar from '../components/navbar';
import { Link } from "react-router-dom";
import Footer from '../components/footer';
import Loader from "../components/loader";
export default function Resultat(){
    return(
        <>
    <div className='wini'>
        <Navbar/>
        
     <div className='buto'>
        <Link to={'/secretaireResultConduite'}> <button className='rec'> RESULTAT CONDUITE</button></Link>
        <Link to={'/secretaireResultCode'}>   <button  className='rev'>RESULTAT CODE</button></Link>
        

        </div>
        <div className='buto'>
        
        <Link to={'/oldCandidat'}> <button className='rea'> ANCIENS CANDIDATS</button></Link>
        </div>
        <br/>  
    </div>
    <Footer/>
    </>
    )
}