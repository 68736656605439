import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moni from '../components/image/moni.png';
import secre from '../components/image/images.png';
import Footer from '../components/footer';
import { Link, NavLink, useNavigate, useParams ,  useLocation} from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import logo from '../components/image/logo4.jpg';
import { sidedataDir } from '../components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import log from '../components/image/log.png';
import { API_BASE_URL } from '../apiConfig';
export default function Employe() {
 

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))

    const [error, setError] = useState(null);

    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    var navigate= useNavigate()
    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])


    
    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post(API_BASE_URL +'/setToken', { gestauto, titre}, { headers})
    .then(res => 
        {setTok(res.data[0].token)      

        })
    .catch(err => console.log(err));
}, [])
//nav en haut
    const[profil, setProfil]= useState(false)

    const showProfil = () => setProfil(!profil)

    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])

    var o
    const {id}= useParams();
    if (id){
        o=id
    }else{
        
        o=0
    }

    const location = useLocation(); 

            // ici bar
            var bar 
            if(largeurEcran>= 1200){
                bar=<div className="barHaut">
 <div className="lep">
                    
                    
                    <img src={moni} className="user" onClick={showProfil} />
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    </div>

                <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
        <NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
            <NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
            
            
            <NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
            <NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
            <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
            <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
            <NavLink to={'/employe/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Employés</button> </NavLink>
           
            <Link onClick={logout} className="link">
    <img src={log} className="am" />
    <div className="deco">DECONNEXION</div>
    
    </Link> 
            
              
        
        </div>
        
        
            }else{
                bar=  <IconContext.Provider value={{color:'#fff'}} >
                             
                <div className="icon">
             <div>
             &nbsp;&nbsp;&nbsp;
             <img src={logo} className="userLog"  /> 
             </div>
                   
             <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div> 
             <div className="navbar">
             
                <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                    <FaIcons.FaBars onClick={showSidebar} />
                </Link>
             
                &nbsp;&nbsp;&nbsp;
                  
             
             </div>
             
             <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className="nav-menu-items" onClick={showSidebar}>
                    <li className="navbar-toggle">
                        <Link to="#" className="menu-bars">
                           <AiIcons.AiOutlineClose/>
                        </Link>
                    </li>

                    <div className="carD">
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil" />
        <div className="infos-utilisateur">
           <p className="inPo"> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>
      </div>
                    {sidedataDir.map((item, index) =>{
                 const isActive = location.pathname.startsWith(item.path);
                 return (
                  
                   <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                                    <Link to={item.path+'/'+o}>
                           <span>{item.title}</span>
                        </Link>
                       
                   </li>
                   
                 
                 )
             
                    })}
                   <li className="nav-text">
                    <Link onClick={logout}  >
                         
                           <span>Deconnexion</span>
                           
                      </Link>
                      </li> 
                </ul>
             </nav>
             </div>
             </IconContext.Provider>
                     
             }




             const [id_annexe]= useState(Cookies.get('autoa'))

             const [secretaires, setSecretaires] = useState([]);
    const [moniteurs, setMoniteurs] = useState([]);

    useEffect(() => {
        const fetchProfils = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/profils/${id_annexe}`);
                setSecretaires(response.data.secretaires);
                setMoniteurs(response.data.moniteurs);
            } catch (error) {
                console.error('Erreur lors de la récupération des profils :', error);
            }
        };

        fetchProfils();
    }, [id_annexe]);


 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
   


/* 
    const openModal = (user, role) => {
        setSelectedUser({ ...user, role });
        setIsModalOpen(true);
    }; */

    // États temporaires pour stocker les permissions avant validation

    
    

    const closeModal = () => {
        setSelectedUser(null);
        setIsModalOpen(false);
     
           /*  window.location.reload(); */
      
    };




    useEffect(() => {
        const fetchProfils = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/profils/${id_annexe}`);
                setSecretaires(response.data.secretaires);
                setMoniteurs(response.data.moniteurs);
            } catch (error) {
                console.error('Erreur lors de la récupération des profils :', error);
            }
        };

        fetchProfils();
    }, [isModalOpen]);



    const [secretaryPermissions, setSecretaryPermissions] = useState({
        access_student_info: false,
        manage_registrations: false,
        manage_payments: false,
    });
    
    const [monitorPermissions, setMonitorPermissions] = useState({
        view_info: false,
        access_student_info: false,
        view_dep : false,
    });




    
    useEffect(() => {
        if (selectedUser) {
            if (selectedUser.role === 'secretaire') {
                setSecretaryPermissions({
                    access_student_info: selectedUser.access_student_info,
                    manage_registrations: selectedUser.manage_registrations,
                    manage_payments: selectedUser.manage_payments,
                });
            } else if (selectedUser.role === 'moniteur') {
                setMonitorPermissions({
                    view_info: selectedUser.view_info,
                    access_student_info: selectedUser.access_student_info,
                    view_dep: selectedUser.view_dep,
                });
            }
        }
    }, [selectedUser]);



    const [tempSecretaryPermissions, setTempSecretaryPermissions] = useState(secretaryPermissions);
    const [tempMonitorPermissions, setTempMonitorPermissions] = useState(monitorPermissions);
    
        const openModal = (user, role) => {
            setSelectedUser({ ...user, role });
            if (role === 'secretaire') {
                setTempSecretaryPermissions(secretaryPermissions);
            } else if (role === 'moniteur') {
                setTempMonitorPermissions(monitorPermissions);
            }
            setIsModalOpen(true);
        };




    
    
        useEffect(() => {
            if (selectedUser?.role === 'secretaire') {
                setTempSecretaryPermissions(secretaryPermissions);
            } else if (selectedUser?.role === 'moniteur') {
                setTempMonitorPermissions(monitorPermissions);
            }
        }, [secretaryPermissions, monitorPermissions, selectedUser]);

   



    const updateSecretaryPermissions = async (id, updatedPermissions) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/secretaires/${id}`, updatedPermissions);
            console.log(response.data.message, response.data.permissions);
            
            setSecretaryPermissions((prev) => ({
                ...prev,
                ...response.data.permissions,
            }));
        } catch (error) {
            console.error('Erreur lors de la mise à jour des autorisations des secrétaires :', error.response?.data?.message || error.message);
        }
    };
    
    const updateMonitorPermissions = async (id, updatedPermissions) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/moniteursOP/${id}`, updatedPermissions);
            console.log(response.data.message, response.data.permissions);
            
            setMonitorPermissions((prev) => ({
                ...prev,
                ...response.data.permissions,
            }));
        } catch (error) {
            console.error('Erreur lors de la mise à jour des autorisations des moniteurs :', error.response?.data?.message || error.message);
        }
    };
    



/* 
    const handleToggle = (id, option) => {
        if (selectedUser.role === 'secretaire') {
            setSecretaryPermissions((prev) => {
                const newPermissions = { ...prev, [option]: !prev[option] };
                updateSecretaryPermissions(id, newPermissions);
                return newPermissions;
            });
        } else if (selectedUser.role === 'moniteur') {
            setMonitorPermissions((prev) => {
                const newPermissions = { ...prev, [option]: !prev[option] };
                updateMonitorPermissions(id, newPermissions);
                return newPermissions;
            });
        }
    }; */


    const handleToggle = (option) => {
        if (selectedUser.role === 'secretaire') {
            setTempSecretaryPermissions((prev) => ({
                ...prev,
                [option]: !prev[option],
            }));
        } else if (selectedUser.role === 'moniteur') {
            setTempMonitorPermissions((prev) => ({
                ...prev,
                [option]: !prev[option],
            }));
        }
    };
    
    
    const confirmPermissions = async () => {
        if (selectedUser.role === 'secretaire') {
            await updateSecretaryPermissions(selectedUser.id, tempSecretaryPermissions);
            setSecretaryPermissions(tempSecretaryPermissions);
        } else if (selectedUser.role === 'moniteur') {
            await updateMonitorPermissions(selectedUser.id, tempMonitorPermissions);
            setMonitorPermissions(tempMonitorPermissions);
        }
        closeModal();
    };
    


    

    return (
        <>    
            <div className='wini'>
                {bar}
                <div className="employ-profils-container">
                   {/*  <h2 className="employ-h2">Secrétaires</h2> */}
                   <div className="employ-h2-container">
  <h2 className="employ-h2">Secrétaires</h2>
</div>
                    <div className="employ-profils-grid">
                        {secretaires.map(secretaire => (
                            <div key={secretaire.id} className="employ-profil-card">
                                <img src={secretaire.image ? `${secretaire.image}` : secre} alt={`${secretaire.nom} ${secretaire.prenom}`} className="employ-profile-image" />
                                <h3>{secretaire.nom} {secretaire.prenom}</h3>
                                <button className="employ-action-button" onClick={() => openModal(secretaire, 'secretaire')}>Gérer Autorisations</button>

                            </div>
                        ))}
                    </div>
                    <div className="employ-h2-container">
                    <h2 className="employ-h2">Moniteurs</h2>
                    </div>
                    <div className="employ-profils-grid">
                        {moniteurs.map(moniteur => (
                            <div key={moniteur.id} className="employ-profil-card">
                                <img src={moniteur.image ? `${moniteur.image}` : moni} alt={`${moniteur.nom} ${moniteur.prenom}`} className="employ-profile-image" />
                                <h3>{moniteur.nom} {moniteur.prenom}</h3>
                                <button className="employ-action-button" onClick={() => openModal(moniteur, 'moniteur')}>Gérer Autorisations</button>

                            </div>
                        ))}
                    </div>
                </div>
    
                {isModalOpen && (
                    <div className="employ-modal-overlay">
                        <div className="employ-modal-content">


         
                        <p className="employ-modal-note">
     Activer ces options permet à l'utilisateur d'accéder aux informations spécifiques des candidats. Cochez les cases correspondantes pour accorder ou restreindre ces accès.
</p>


                            <button className="employ-modal-close-button" onClick={closeModal}>×</button>
                           
                           

                            <h2 className="employ-modal-title"> {selectedUser.nom} {selectedUser.prenom} peut avoir :</h2>
            

{selectedUser && selectedUser.role === 'secretaire' && (
                    <>
                       
                        <div className="employ-modal-option">
                            <span className="employ-modal-option-label">Accès à la liste des depenses</span>
                            <label className="employ-modal-toggle">
                                <input
                                    type="checkbox"
                                    checked={tempSecretaryPermissions.manage_registrations}
                                    onChange={() => handleToggle('manage_registrations')}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                        <div className="employ-modal-option">
                            <span className="employ-modal-option-label">Accès à la liste des recettes</span>
                            <label className="employ-modal-toggle">
                                <input
                                    type="checkbox"
                                    checked={tempSecretaryPermissions.manage_payments}
                                    onChange={() => handleToggle('manage_payments')}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                         <div className="employ-modal-option">
                            <span className="employ-modal-option-label">Accès aux paiements du candidat</span>
                            <label className="employ-modal-toggle">
                                <input
                                    type="checkbox"
                                    checked={tempSecretaryPermissions.access_student_info}
                                    onChange={() => handleToggle('access_student_info')}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                    </>
                )}
                            
                            {selectedUser && selectedUser.role === 'moniteur' && (
                                <>

                                       <div className="employ-modal-option">
                            <span className="employ-modal-option-label">Accès à la liste des depenses</span>
                            <label className="employ-modal-toggle">
                                <input
                                    type="checkbox"
                                    checked={tempMonitorPermissions.view_dep}
                                    onChange={() => handleToggle('view_dep')}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                                    <div className="employ-modal-option">
                                        <span className="employ-modal-option-label">Accès à la liste des recettes</span>
                                        <label className="employ-modal-toggle">
                                            <input
                                                type="checkbox"
                                                checked={tempMonitorPermissions.view_info}
                                                onChange={() => handleToggle('view_info')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>

                             

                                    <div className="employ-modal-option">
                                        <span className="employ-modal-option-label">Accès aux paiements du candidat</span>
                                        <label className="employ-modal-toggle">
                                            <input
                                                type="checkbox"
                                                checked={tempMonitorPermissions.access_student_info}
                                                onChange={() => handleToggle('access_student_info')}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </>
                            )}
    
                        {/*     <button className="employ-action-button" onClick={closeModal}>Fermer</button> */}
                            <button className="employ-action-button" onClick={confirmPermissions}>Confirmer</button>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
    
   

} 

