import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../apiConfig';
export default function UpdateDepenseFixe() {
    const [libelle, setLibelle] = useState('');
    const [montant, setMontant] = useState('');
    const [startDate, setStartDate] = useState('');
    const [ecole, setEcole] = useState('');
    const [info, setInfo] = useState('');
    const [loader, setLoader] = useState(false);
   const [fixeID,setFixeId] = useState('')
    const { id } = useParams(); // ID de la dépense à modifier
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        axios.get(`${API_BASE_URL}/get-depense/${id}`)
            .then(res => {
                setLibelle(res.data.libelle);
                setMontant(res.data.montant);

               setStartDate( moment(res.data.date).utc(1).format('YYYY-MM-DD'))
              setFixeId(res.data.depense_id)
                setEcole(res.data.id_ecole)
                console.log('les infos',res.data)
            })
            .catch(err => {
                console.error('Erreur lors de la récupération de la dépense :', err);
            });
    }, [id]);

    const handleUpdate = (event) => {
        event.preventDefault();
        setLoader(true);

        if (!libelle || !montant || !startDate) {
            setInfo("Veuillez remplir tous les champs obligatoires.");
            setLoader(false);
            return;
        }else if (!/^\d+$/.test(montant) ) {
            setLoader(false)
            setInfo("Veuillez entrer un montant entier sans virgule.");
           return;

          }

        axios.post(`${API_BASE_URL}/update-depense-fixe/${id}`, { libelle, montant, startDate })
            .then(() => {
                setLoader(false);
                navigate('/depenseDir/'+ecole); // Redirige vers la page des dépenses après mise à jour
            })
            .catch(err => {
                setLoader(false);
                setInfo('Erreur lors de la mise à jour.');
                console.error('Erreur lors de la mise à jour de la dépense :', err);
            });
    };




    const handleDeleteConfirmation = () => {
        setShowModal(true); // Affiche la modale
    };

    const handleDelete = () => {
        setLoader(true);
        axios.delete(`${API_BASE_URL}/delete-depense-fixe/${id}`)
            .then(() => {
                setLoader(false);
                setShowModal(false); // Ferme la modale
           
                navigate('/depenseDir/'+ecole);
            })
            .catch(err => {
                setLoader(false);
                setInfo("Erreur lors de la suppression de la charge.");
                console.error("Erreur lors de la suppression de la charge :", err);
            });
    };

    const handleGoBack = () => {
        navigate('/depenseDir/'+ecole);
      };

    return (
      


<form className="depfixe-form" onSubmit={handleUpdate}>
<FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'black', fontSize: '28px', cursor: 'pointer' }} />
<h2 className="depfixe-titre">Modifier la charge Fixe</h2>

<label htmlFor="depfixe-nom">Libelle :</label>
<input type="text" id="depfixe-nom" name="nom-depense" value={libelle} onChange={e => setLibelle(e.target.value)} required/>

<label htmlFor="depfixe-montant">Montant :</label>
<input type="number" id="depfixe-montant" name="montant" value={montant} onChange={e => setMontant(e.target.value)} required/>

 <label htmlFor="depfixe-start-date">Date :</label>
    <input
      type="date"
      id="depfixe-start-date"
      value={startDate} onChange={e => setStartDate(e.target.value)} required
    />

<button type="submit" className="depfixe-bouton">Mettre à jour</button>
<button type="button" className="depfixe-supprimer" onClick={handleDeleteConfirmation}>Supprimer</button>
{loader && <p>Chargement...</p>}
{info && <h4 className="text-danger">{info}</h4>}



{showModal && (
                <div className="depfixe-modal-overlay">
                    <div className="depfixe-modal-content">
                        <h4>Êtes-vous sûr de vouloir supprimer cette charge ?</h4>
                        <p>Cette action est irréversible.</p>
                        <button onClick={handleDelete} className="depfixe-modal-confirm">Oui, Supprimer</button>
                        <button onClick={() => setShowModal(false)} className="depfixe-modal-cancel">Annuler</button>
                    </div>
                </div>
            )}


</form>
    );
}
