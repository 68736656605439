import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from '../apiConfig';


function Auth(){
    const [name, setName]= useState('')
    const [password, setPassword]= useState('')
    
    const [loginStatut, setLoginStatut]= useState('')
    
    const navigate = useNavigate();
  
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
   
 
      function handleSubmit(event){
       
        event.preventDefault();
        
        if(name.substring(0, 2)== "DG" || name.substring(0, 2)== "Dg" || name.substring(0, 2)== "dg" ){
            const choix= 'directeur'
   if(choix=='directeur'){
    //start
axios.post(API_BASE_URL +'/auth', {name,password,choix}, { headers })
.then(res => {

 if(res.data.message!= ''){
   
 
 if(res.data.message == "Mauvais username ou mot de passe"){
     setLoginStatut(res.data.message);

 }else{
     const id= res.data.message;
     const token= res.data.message2
   
     if(id && token){
     axios.post(API_BASE_URL +'/token', {choix,id}, { headers})
     .then(res => {
    
         if(res.data.message== 'new'){
             axios.post(API_BASE_URL +'/insert', {choix,token,id}, { headers})
             .then(res => {
                 if(res.data.message== 'err'){
                     console.log('pb cookIN')
                     
                 }else {
                   
                         navigate('/accueil2/'+0)
                                    
                 }
             }).catch(err => console.log(err));                  
         }else if(res.data.message== 'exist'){
             axios.put(API_BASE_URL +'/tokenUp/'+id, {choix,token,id}, { headers})
             .then(res => {                  
                 if(res.data.message== 'err'){
                     console.log('pb cookUp')                     
                 }else {
                  
                         navigate('/accueil2/'+0)
                   
                 }
             }).catch(err => console.log(err));
         }
     }).catch(err => console.log(err));       
 }else{
    console.log('err with message 1 or 2')
 }
}

}

}).catch(err => console.log(err));

//end

   }

 }else if(name.substring(0, 10)== "Secretaire" || name.substring(0, 10)== "secretaire"){
     const choix='secretaire'
     if(choix=='secretaire'){
 //start secretaire
 axios.post(API_BASE_URL +'/auth', {name,password,choix}, { headers})
 .then(res => {
 
  if(res.data.message!= ''){
  
  if(res.data.message == "Mauvais username ou mot de passe"){
      setLoginStatut(res.data.message);
 
  }else{
      const id= res.data.message;
      const token= res.data.message2
      
     if(id && token ){
      axios.post(API_BASE_URL +'/token', {choix,id}, { headers})
      .then(res => {
     
          if(res.data.message== 'new'){
              axios.post(API_BASE_URL +'/insert', {choix,token,id}, { headers})
              .then(res => {
                  if(res.data.message== 'err'){
                      console.log('pb cookIN')
                      
                  }else {
                      
                          navigate('/accueil');
                          setLoginStatut("Succes")
                                    
                  }
              }).catch(err => console.log(err));                  
          }else if(res.data.message== 'exist'){
              axios.put(API_BASE_URL +'/tokenUp/'+id, {choix,token,id}, { headers})
              .then(res => {                  
                  if(res.data.message== 'err'){
                      console.log('pb cookUp')                     
                  }else {
                      
                          navigate('/accueil');
                          setLoginStatut("Succes")
                      
                    
                     
                  }
              }).catch(err => console.log(err));
          }
      }).catch(err => console.log(err));       
  }else{
    console.log("err with message 1 or 2")
}
}

 }
 
 }).catch(err => console.log(err));
      //end secretaire
     }
    
 }else if(name.substring(0, 8)== "Moniteur" || name.substring(0, 8)== "moniteur"){
     const choix='moniteur'
     if(choix=='moniteur'){
        //start moniteur
     axios.post(API_BASE_URL +'/auth', {name,password,choix}, { headers})
     .then(res => {
     
      if(res.data.message!= ''){
      if(res.data.message == "Mauvais username ou mot de passe"){
          setLoginStatut(res.data.message);
     
      }else{
          const id= res.data.message;
          const token= res.data.message2
          if(id && token){
          axios.post(API_BASE_URL +'/token', {choix,id}, { headers})
          .then(res => {
         
              if(res.data.message== 'new'){
                  axios.post(API_BASE_URL +'/insert', {choix,token,id}, { headers})
                  .then(res => {
                      if(res.data.message== 'err'){
                          console.log('pb cookIN')
                          
                      }else {
                          
                              navigate('/accueil');
                                       
                      }
                  }).catch(err => console.log(err));                  
              }else if(res.data.message== 'exist'){
                  axios.put(API_BASE_URL +'/tokenUp/'+id, {choix,token,id}, { headers})
                  .then(res => {                  
                      if(res.data.message== 'err'){
                          console.log('pb cookUp')                     
                      }else {
                         
                              navigate('/accueil');
                          
                          
                      }
                  }).catch(err => console.log(err));
              }
          }).catch(err => console.log(err));       
      }else{
        console.log('err with message1 or 2')
      }
    }
 
     }
     
     }).catch(err => console.log(err));
          //end moniteur
     }
 }else {
     const choix='candidat'

if(choix=='candidat'){
         //start candidat
         axios.post(API_BASE_URL +'/auth', {name,password,choix}, { headers})
         .then(res => {
         
          if(res.data.message!= ''){
          
          if(res.data.message == "Mauvais username ou mot de passe"){
              setLoginStatut(res.data.message);
         
          }else{
              const id= res.data.message;
              const token= res.data.message2
              if(id && token){
              axios.post(API_BASE_URL +'/token', {choix,id}, { headers})
              .then(res => {
             
                  if(res.data.message== 'new'){
                      axios.post(API_BASE_URL +'/insert', {choix,token,id}, { headers})
                      .then(res => {
                          if(res.data.message== 'err'){
                              console.log('pb cookIN')
                              
                          }else {
                                   
                              console.log('connexion success')
                                  navigate('/pageCandidatCand/'+id);
                                            
                          }
                      }).catch(err => console.log(err));                  
                  }else if(res.data.message== 'exist'){
                      axios.put(API_BASE_URL +'/tokenUp/'+id, {choix,token,id}, { headers})
                      .then(res => {                  
                          if(res.data.message== 'err'){
                              console.log('pb cookUp')                     
                          }else {
                             
                                 console.log('connexion success')
                                  navigate('/pageCandidatCand/'+id);
                            
                          }
                      }).catch(err => console.log(err));
                  }
              }).catch(err => console.log(err));       
          }else{
            console.log('err with message1 or 2')
          }
        }
    
         }
         
         }).catch(err => console.log(err));
              //end candidat
}
     
 }

    

      }


 


       

             
    






    return(
        <div className="auth">
            
            
            
            <div className="form">
                 <form onSubmit={handleSubmit}>
                 <h1 className="con">LOGIN</h1>
                   
                    <label htmlFor="nom" className="nomo">USER NAME</label>
                    <br/>
                    <input type="text" name="nom"  id="nom" onChange={e => setName(e.target.value)} className="type"  ></input>
                    
                    <br/>
                    <label htmlFor="password" className="password">MOT DE PASSE</label>
                    <input type="password" name="password" id="password" className="type" onChange={e => setPassword(e.target.value)}></input>
                    <br/>
                     

                    <button type="submit" className='button' >LOGIN</button>
                    <h3 className="text-danger">{loginStatut}</h3><br/>
                  
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                 </form>
            </div> 
        </div>
    )
}
export default Auth