import React, {useEffect, useState } from "react"
import axios from "axios";
import { Link, useNavigate, useParams,NavLink } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import frLocale from '@fullcalendar/core/locales/fr'; // Importer la locale française
import listPlugin from '@fullcalendar/list';
import moment from "moment";
import Loader from "./loader";
import Cookies from 'js-cookie';
import Versement from "./versement";
import Prese from "./prese";
import Rese from "./rese";
import jsPDF from "jspdf";
import { API_BASE_URL } from '../apiConfig';
export default function Option(){
  const [page,setPage]= useState('versement')

  const [titre,setTitre]= useState(Cookies.get('titre'))
    
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
      'credentials': 'include',
    };
  const [query, setQuery]= useState(""); 
  const [stat,setStat]= useState("")
  const [le, setLe]= useState('')
  const [le2,setLe2]= useState('')
  const [name, setName]= useState('')
  const [prenom, setPrenom]= useState('')
  const [total, setTotal]= useState('')
  const [contact, setContact]= useState('')
  const [sexe, setSexe]= useState('')
  const [adresse, setAdresse]= useState('')
  const [age,setAge]= useState('')
  const [montant, setMontant]= useState('')
  const [statut, setStatut]= useState('')
  const [date, setDate]= useState('')
  const [nombre, setNombre]= useState([])
  const [nombre2, setNombre2]= useState([])
  const [post,setPost] = useState("")
 
  const {id}= useParams();
  const [error, setError] = useState(null);
    const [loader, setLoader]= useState(false)
  
  const [payement, setPayement]= useState([])
  
  const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    
  const mettreAJourLargeurEcran = () => {
      setLargeurEcran(window.innerWidth);
  
    };
    useEffect(() => {
      
      window.addEventListener('resize', mettreAJourLargeurEcran);
  
     
      return () => {
        window.removeEventListener('resize', mettreAJourLargeurEcran);
      };
    }, []);

  const [secretaire, setSecretaire] = useState([])
  useEffect(()=>{
      axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers })
      .then(res => setSecretaire(res.data))
      .catch(err => {
        console.log(err)
        setError(err)
        setLoader(false)
      });
  }, [])
  var effet
     if(secretaire.map((data)=>(data.poste))=='secretaire' || secretaire.map((data)=>(data.poste))=='directeur')  {
      effet=<Link to={'/pageCandidatPaye/'+le2} className='linko'><button className="ajoutons">PAYER</button> </Link>
     }else{
      effet=''
     }
  function handleSubmit(event){
      event.preventDefault();
      
      
  }

  const [candidat, setCandidat] = useState([])
  const [ecoleId, setEcoleId]= useState(Cookies.get('olaf'));
 
  const [complete,setComplete]= useState('')
  useEffect(()=>{
      axios.get(API_BASE_URL +'/update/'+id, { headers })
      .then(res => { 
          setLe(res.data[0].id)
          setName(res.data[0].nom)
          setPrenom(res.data[0].prenom)
          setContact(res.data[0].contact)
          setSexe(res.data[0].sexe)
          setAdresse(res.data[0].adresse)
          setAge(res.data[0].age)
          setMontant(res.data[0].montant_payer)
          setStatut(res.data[0].statut_payement)
          setDate(res.data[0].date)
          setCandidat(res.data)
          setComplete (res.data[0].heure)
          setTotal(res.data[0].montant_total)
        /*   setEcoleId(res.data[0].id_ecole) */
          if(res.data[0].resultat_conduite>=12
            ){
            setStat('ADMIS')
        }
        else if(res.data[0].resultat_code<14 && res.data[0].resultat_code!=null){
          setStat('NON ADMIS')
        }
        else if(res.data[0].resultat_conduite==null){
          setStat('')
        }else{
          setStat('NON ADMIS')
        }
      
      }
          
          
          
     
      
         


          
          )
      .catch(err => {
        console.log(err)
        setError(err)
        setLoader(false)
      });

  
  }, [])
  if(name!='' || prenom!=""){
    axios.post(API_BASE_URL +'/affichePayement',{le}, { headers })
    .then(res =>{

     setPayement(res.data)
     setLoader(false)
    }).catch(err =>{
       console.log(err)
      setError(err)
      setLoader(false)
      })
  setName('')
  setPrenom('')
  }
  

let totalPaye=0
const first=montant



payement.map((data, i)=> ( 
              
  totalPaye=totalPaye+data.montant    

))
  const paye= first+totalPaye
  
 const calcul= total 


 if(le!=''){
  axios.post(API_BASE_URL +'/nombre2',{le}, { headers }, { headers })
  .then(res => setNombre2(res.data)
 ).catch(err => {
  console.log(err)
  setError(err)
  setLoader(false)
})
 
 axios.post(API_BASE_URL +'/nombre',{le})
  .then(res => setNombre(res.data)
 ).catch(err => {
  console.log(err)
  setError(err)
  setLoader(false)
})
 setLe2(le)
 setLe('')
 }
 
      
 




// Récupération des événements pour la conduite
const conduiteEvents = nombre.map((data) => ({
  title: 'Cours de Conduite',
  start: moment(data.date).format('YYYY-MM-DD'), 
  time: data.time,
}));

// Récupération des événements pour le code
const codeEvents = nombre2.map((data) => ({
  title: 'Cours de Code',
  start: moment(data.date).format('YYYY-MM-DD'),
  time: data.time,
}));

// Tri des événements par date
conduiteEvents.sort((a, b) => moment(a.start) - moment(b.start));
codeEvents.sort((a, b) => moment(a.start) - moment(b.start));
 




const [infoAuto, setInfoAuto] = useState('')
const [localisation, setLocalisation] = useState('')
const [logoA, setLogoA]= useState('')
const [rcm, setRcm] = useState('')
const [ifu, setIfu] = useState('')
const [autorisation, setAutorisation] = useState('')
const [mail, setMail] = useState('')
const [NB,setNB]= useState('')
const [time, setTime]= useState('')









useEffect(()=>{
     
  axios.get(API_BASE_URL +'/infoAuto?id='+ecoleId+'&titre='+titre+'', { headers })
  .then(res => {
    
      setInfoAuto(res.data[0].contact)
      setLocalisation(res.data[0].localisation)
     setLogoA(res.data[0].logo)
     setRcm(res.data[0].rcm)
     setIfu(res.data[0].ifu)
     setAutorisation(res.data[0].autorisation)
     setMail(res.data[0].mail)
     setNB(res.data[0].nb)
    
  })
  .catch(err => console.log(err));
}, [])





const loadImageAsBase64 = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};


const logoUrl = logoA ? `${API_BASE_URL}/get-image?logo=${logoA}` : null;





const generatePDF = async (
  name,
  prenom,
  montant,
  total,
  date,
  referencePaiement,
  imageUrl,
  localisation,
  infoAuto,
  secretaire,     // Ajouté comme paramètre
  mail,           // Ajouté comme paramètre
  categorie,      // Ajouté comme paramètre
  langue,         // Ajouté comme paramètre
  dure,           // Ajouté comme paramètre
  ifu,            // Ajouté comme paramètre
  rcm,            // Ajouté comme paramètre
  autorisation,   // Ajouté comme paramètre
  heure,          // Ajouté comme paramètre     // Ajouté comme paramètre
  NB              // Ajouté comme paramètre
) => {
  // Créer une nouvelle instance de jsPDF
  const doc = new jsPDF();
  const reste = total - montant;

  try {
    if (imageUrl) {
      const base64Logo = await loadImageAsBase64(imageUrl); // Utilisation de await pour charger l'image
      doc.addImage(base64Logo, 'PNG', 20, 10, 30, 30);
    }
  } catch (error) {
    console.error("Erreur lors du chargement de l'image :", error);
    doc.text("Logo non disponible", 20, 10);
  }

  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0); // Couleur noire

  // Ajouter le nom de l'auto-école
  doc.text(`Auto Ecole ${secretaire.map((data) => data.auto_ecole)}`, 105, 15, null, null, 'center');

  if (infoAuto) {
    doc.setFontSize(10);
    doc.text(`TEL : ${infoAuto}`, 105, 20, null, null, 'center');
  }
  if (mail) {
    doc.text(`Email : ${mail}`, 105, 25, null, null, 'center');

    if (localisation) {
      const maxLineWidth = 120; // Largeur maximale pour la ligne
      const locationText = ` ${localisation}`;
      const lines = doc.splitTextToSize(locationText, maxLineWidth);

      // Dessine chaque ligne sur le PDF
      doc.setFontSize(10);
      lines.forEach((line, index) => {
        doc.text(line, 105, 30 + (index * 5), null, null, 'center');
      });
    }
  } else {
    if (localisation) {
      const maxLineWidth = 120; // Largeur maximale pour la ligne
      const locationText = ` ${localisation}`;
      const lines = doc.splitTextToSize(locationText, maxLineWidth);

      doc.setFontSize(10);
      lines.forEach((line, index) => {
        doc.text(line, 105, 25 + (index * 5), null, null, 'center');
      });
    }
  }

  // Insérer le titre de la quittance
  doc.setFontSize(20);
  doc.setTextColor(25, 25, 112);
  doc.text('Quittance de Paiement', 105, 48, null, null, 'center');

  // Date et heure
  doc.setFontSize(11);
  doc.text(`Date: ${moment(date).utc(1).format('DD-MM-YYYY')}`, 20, 55);
  doc.text(`Heure: ${heure}`, 20, 60);

  // Informations IFU, RCCM, Autorisation
  if (ifu) {
    doc.setFontSize(9);
    doc.text(`IFU : ${ifu}`, 105, 55);
  }
  if (rcm) {
    doc.setFontSize(9);
    doc.text(`N°RCCM : ${rcm}`, 105, 60);
  }
  if (autorisation) {
    doc.setFontSize(9);
    doc.text(`Référence de l’autorisation : ${autorisation}`, 105, 65);
  }

  // Ligne de séparation
  doc.setLineWidth(0.5);
  doc.line(20, 68, 190, 68);

  // Nom, prénom et référence de paiement
  doc.setFontSize(11);
  doc.setFont('helvetica', 'bold');
  doc.text(`${prenom} ${name}`, 31, 75);

  // Autres informations
  doc.setFontSize(11);
  doc.setTextColor(25, 25, 112);
  doc.setFont('helvetica', 'normal');
  doc.text(`Catégorie: Permis ${categorie}`, 20, 80);
  doc.text(`Langue de Formation: ${langue}`, 20, 85);
  doc.text(`Durée de Formation: ${dure} mois`, 20, 90);
  doc.text(`Référence de Paiement: ${referencePaiement}`, 105, 80);

  // Tableau des informations de paiement
  const data = [
    ['Frais de Formation', `${total} FCFA`],
    ['Montant payé', `${montant} FCFA`],
    ['Reste à payer', `${reste} FCFA`]
  ];

  // Utiliser autoTable pour générer le tableau
  doc.autoTable({ startY: 100, head: [['Désignation', 'Valeur']], body: data });

  // Montant total payé
  doc.setFontSize(11);
  doc.text(`Arrêté le présent reçu à la somme de: ${montant} FCFA`, 20, doc.autoTable.previous.finalY + 10);

  // Remarque
/*   doc.setFontSize(11);
  doc.setTextColor(25, 25, 112);
  doc.text(`NB: ${NB}`, 20, doc.autoTable.previous.finalY + 20, 'center'); */

  // Signature
  /* doc.setFontSize(11);
  doc.text('Signature:', 20, doc.autoTable.previous.finalY + 30); */

  doc.setFontSize(11);
doc.text('Signature:', 20, doc.autoTable.previous.finalY + 20);

  if(NB){
    doc.setFontSize(11);
    doc.setTextColor(255, 0, 0);
    doc.text(`NB: ${NB}`, 105, doc.autoTable.previous.finalY + 30, 'center');

  }else{
    doc.setFontSize(11);
    doc.setTextColor(255, 0, 0);
    doc.text(`NB: `, 105, doc.autoTable.previous.finalY + 30, 'center');
  }


  // Sauvegarder le PDF
  doc.save(`${prenom}_${name}_quittance.pdf`);
};





const generatePDF2 = async(name, prenom,  montant, total,paye,
  date,referencePaiement,imageUrl,localisation,infoAuto,
  mail,categorie,langue,dure,ifu,rcm,autorisation,heure,NB,montant_payer,idCible) => {
// Créer une nouvelle instance de jsPDF
const doc = new jsPDF();
const reste = total - paye;



try {
if (imageUrl) {
const base64Logo = await loadImageAsBase64(imageUrl); // Utilisation de await pour charger l'image
doc.addImage(base64Logo, 'PNG', 20, 10, 30, 30);
}
} catch (error) {
console.error("Erreur lors du chargement de l'image :", error);

}




doc.setFontSize(12);
doc.setTextColor(0, 0, 0); // Couleur noire

doc.text(`Auto Ecole ${secretaire.map((data) => data.auto_ecole)}`, 105, 15, null, null, 'center');

if (infoAuto) {
doc.setFontSize(10);
doc.text(`TEL : ${infoAuto}`, 105, 20, null, null, 'center');
}
if (mail) {
doc.text(`Email : ${mail}`, 105, 25,null,null,'center');

if (localisation) {
// Limite de caractères par ligne (ajustez si nécessaire)
const maxLineWidth = 120; // Largeur maximale pour la ligne, ajustez selon vos marges

// Découpe le texte en plusieurs lignes si nécessaire
const locationText = ` ${localisation}`;
const lines = doc.splitTextToSize(locationText, maxLineWidth);

// Dessine chaque ligne sur le PDF
doc.setFontSize(10);
doc.setTextColor(0, 0, 0); // Couleur noire
lines.forEach((line, index) => {
doc.text(line, 105, 30 + (index * 5), null, null, 'center'); // Ajuste l'espacement entre les lignes
});
}

}else{
if (localisation) {
// Limite de caractères par ligne (ajustez si nécessaire)
const maxLineWidth = 120; // Largeur maximale pour la ligne, ajustez selon vos marges

// Découpe le texte en plusieurs lignes si nécessaire
const locationText = ` ${localisation}`;
const lines = doc.splitTextToSize(locationText, maxLineWidth);

// Dessine chaque ligne sur le PDF
doc.setFontSize(10);
doc.setTextColor(0, 0, 0); // Couleur noire
lines.forEach((line, index) => {
doc.text(line, 105, 25 + (index * 5), null, null, 'center'); // Ajuste l'espacement entre les lignes
});
}
}




// Définir la police et la taille du texte
doc.setFont('helvetica', 'normal');

// Insérer le logo et le nom du système
doc.setFontSize(20);
doc.setTextColor(25, 25, 112);
doc.text('Quittance de Paiement', 105, 48, null, null, 'center');




// Date
doc.setFontSize(11);
doc.text(`Date: ${moment(date).utc(1).format('DD-MM-YYYY')}`, 20, 55);
doc.text(`Heure: ${heure}`, 20, 60);


if (ifu) {
doc.setFontSize(9);
doc.text(`IFU : ${ifu}`, 105, 55);

}
if (rcm) {
doc.setFontSize(9);
doc.text(`N°RCCM : ${rcm}`, 105, 60);

}

if (autorisation) {
doc.setFontSize(9);
doc.text(`Référence de l’autorisation : ${autorisation}`, 105, 65);

}





// Ligne de séparation
doc.setLineWidth(0.5);
doc.line(20, 68, 190, 68);

// Nom et prénom du payeur et référence de paiement
doc.setFontSize(11);

doc.setFont('helvetica', 'bold');

doc.text(`${prenom} ${name}`, 31, 75);



doc.setFontSize(11);
doc.setTextColor(25, 25, 112);
doc.setFont('helvetica', 'normal');
if(categorie){
doc.text(`Catégorie: Permis ${categorie}`, 20, 80);
}
doc.text(`Langue de Formation: ${langue}`, 20, 85);
doc.text(`Durée de Formation: ${dure}`, 20, 90)
doc.text(`Référence de Paiement: ${referencePaiement}`, 105, 80);

// Tableau des informations
const data = [

['Frais de Formation', `${total} FCFA`],
['Versement 1', `${montant_payer} FCFA`],

];

let avantCible = true;

payement.reduce((acc, element, index) => {
if (element.id === idCible) {
avantCible = false; // Arrête d'ajouter des éléments après avoir trouvé le paiement cible
}

// Si on est toujours avant le paiement cible, ajouter le paiement à `data`
if (avantCible) {
acc.push([`Versement ${index + 2}`, `${element.montant} FCFA`]);
}

return acc;
}, data);


data.push(
['Montant payé', `${montant} FCFA`],
['Reste à payer', `${reste} FCFA`]
);


// Utiliser autoTable pour générer le tableau
doc.autoTable({ startY: 100,head: [['Désignation', 'Valeur']], body: data });
// Calcul du montant restant
doc.setFontSize(11);
doc.text(`Arrêté le present reçu à la somme de: ${montant} FCFA`, 20, doc.autoTable.previous.finalY + 10);

// Ligne de signature
doc.setFontSize(11);
doc.text(`Signature:`, 20, doc.autoTable.previous.finalY + 20);

if(NB){
doc.setFontSize(11);
doc.setTextColor(255, 0, 0);
doc.text(`NB: ${NB}`, 105, doc.autoTable.previous.finalY + 30, 'center');

}else{
doc.setFontSize(11);
doc.setTextColor(255, 0, 0);
doc.text(`NB: `, 105, doc.autoTable.previous.finalY + 30, 'center');
}


// Sauvegarder le PDF
doc.save(`${prenom}_${name}_quittance.pdf`);
};











var o

  if(page=='versement'){
     o= <Versement/>
  }else if(page=='presence'){
    o= <Prese/>
  }else if(page=='resultat'){
    o= <Rese/>
  }

  if(loader){
    return(
        <div className="per">
        
        <div className="loader">
        <div className="overlayLo"> 
            <div className="loaderP">
            <Loader/>
            </div>
            </div>
            </div>
            </div>
    )
}else if(error){
    return (<div>Une erreur est survenue : {error.message}</div>)
}else{
if(largeurEcran>=880){
  return(


    <>
    
    <div className="option">
    
       
  
<div className="versement">

<br/>

{((((titre === "moniteur" || titre === "secretaire") && 
              secretaire.some(data => data.access_student_info === 1))) || titre=== "directeur" )  && (

<h2 className="title">VERSEMENTS</h2>
              )}


  <div className="">
    <div className="">
      <div className="">

      {effet}

   {/*    {((((titre === "moniteur" || titre === "secretaire") && 
              secretaire.some(data => data.access_student_info === 1))) || titre=== "directeur" )  && (
      <div className="biann"> 
      <table className='ta'>
             <thead >
                    <tr>
                    
                    <th >Versement</th>
                    <th >Libelle</th>
                    <th >Date</th>
                    <th >Montant</th>
                    </tr>
                 </thead>
                
               <tbody>
                      {
                        candidat.map((data, i)=> (
                            <tr>
                                <td className='th'   data-label='Versement'> 1 </td>
                                <td className='th'   data-label='Libelle'>INSCRIPTION</td>
                                <td className='th'   data-label='Date'>{moment(data.date).utc(1).format('DD-MM-YYYY')}</td>
                                <td className='th'   data-label='Montant'>{data.montant_payer} FCFA</td>
                                
                                
                                
                                
                            </tr>
                        ))
                      } 

                       {
                       
                       payement.map((data, i)=> (
                        <tr >
                            <td className='th'   data-label='Versement'> {i+2} </td>
                            <td className='th'   data-label='Libelle'> {data.libelle}</td>
                            <td className='th'   data-label='Date'> {moment(data.date).utc(1).format('DD-MM-YYYY')}</td>
                            <td className='th'   data-label='Montant'>  {data.montant} FCFA</td>
                             
                            
                           
                            
                            
                        </tr>

                    )) 

                    
                      } 

<tr>
         <td className="" ><h3 className='tol'>TOTAL</h3></td>
            <td className=""> </td>
            <td className=""> </td>
            <td className=''><h4 className="ha">{first+totalPaye} FCFA</h4></td>
         </tr>
                     
                 </tbody> 
             </table>
          
             
             

        <div className="omega">
          <div className="ome">
        <h3 className='to'>MONTANT A PAYER </h3>
        <h4>{total} FCFA</h4>
        </div>   
        <div className="ome">
        <h3 className='to'>RESTE A PAYER </h3> 
        <h4>{calcul-paye} FCFA</h4>
            
          </div> 
        </div>    
        </div>
        )} */}







{((((titre === "moniteur" || titre === "secretaire") && 
              secretaire.some(data => data.access_student_info === 1))) || titre=== "directeur" )  && (
              
                  <div className="bloom">
                  {
                        
                        candidat.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle"> versement 1</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                          
                             
                             
        {/* Bouton pour générer le PDF */}
       <div className="downI">
             <i class="fas fa-download" onClick={() => generatePDF(
            data.nom,
            data.prenom,
            data.montant_payer,
            data.montant_total,  // Assure-toi que 'total' existe dans les données
            data.date,
            data.reference,  // Si cette donnée existe
            logoUrl,  // Si cette donnée existe
            localisation,
            infoAuto,
            secretaire,
            mail,
            data.categorie,
            data.langue,
            data.dure,
            ifu,
            rcm,
            autorisation,
            data.heure,          
            NB
          )}></i> 
     </div>
                          </div>
                        ))
                      }
                         
                  {
                        
                        payement.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).map((data, i)=>{
                            const sum = payement
                            .filter((p) => new Date(p.id) < new Date(data.id)) 
                            .reduce((acc, p) => acc + Number(p.montant), 0); 

                           return(
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle"> versement {i+2}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                          
                              {/* Bouton pour générer le PDF */}
       <div className="downI">
             <i class="fas fa-download" onClick={() => generatePDF2(
            data.nom,
            data.prenom,
            data.montant,
            total,  // Assure-toi que 'total' existe dans les données
            sum+data.montant+Number(candidat.map((data) => data.montant_payer)),
            data.date,
            data.reference,  // Si cette donnée existe
            logoUrl,  // Si cette donnée existe
            localisation,
            infoAuto,
            mail,
            candidat.map((data) => data.categorie),
            candidat.map((data) => data.langue),
            candidat.map((data) => data.dure),
            ifu,
            rcm,
            autorisation,
            data.heure,
            NB,
            candidat.map((data) => data.montant_payer),
            data.id
          )}></i> 
     </div> 
                          </div>
                        )})
                      }

<div className="omega">
                      <div className="ome">
                    <h3 className='tool'>MONTANT A PAYER </h3>
                    <h4>{total} FCFA</h4>
                    </div>   
                    <div className="ome">
                    <h3 className='tool'>RESTE</h3> 
                    <h4>{calcul-paye} FCFA</h4>
                        
                      </div> 
                    </div>   
                    </div>
              )}






        </div>
    </div>
        </div>




        <div className="calendar-container">
      <h2 className="title">PRESENCE</h2>
      <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          locale="fr"
          events={[...conduiteEvents, ...codeEvents]} // Combiner les événements pour le calendrier
          buttonText={{
            today: 'Aujourd\'hui', // Change le texte du bouton "Today" à "Aujourd'hui"
          }}
          eventContent={(eventInfo) => (
            <div>
              <b>{eventInfo.event.title}</b>
              <div>à {eventInfo.event.extendedProps.time}</div>
            </div>
          )}
          height="auto"
        />
    </div>






<br/>
<br/>
<div className="bourse">
<div className="itec">

{/* <div>

<h2 className="title">PRESENCE</h2>

<div className="biou">
 
     <div>
    
     <table className='tah'>
         
            <tr>
            <th className="ninh"> Presence cours de Code : {nombre2.length}</th>                    
              </tr>
            
         
              {  
                nombre2.map((data, i)=> ( 
                    <tr>        
                        <td className='tbilan' ><div className="timus">{moment(data.date).utc(1).format('DD-MM-YYYY')} {data.time}</div></td>                                              
                    </tr>     
                ))
              }  
     </table>
     </div>
     <br/>
     <div>
   
   <table className='tah'>

<tr>
<th className="ninh">Presence cours de Conduite : {nombre.length}</th>                    
</tr>


{  
  nombre.map((data, i)=> ( 
      <tr>        
          <td className='tbilan'><div className="timus">{moment(data.date).utc(1).format('DD-MM-YYYY')} {data.time}</div></td>                                              
      </tr>     
  ))
}  
</table>
</div>
    
 </div>
 </div> */}
 
{/*  <div>   
<div className="space">
  
<h2 className="title">RESULTATS</h2>
<div className="recadre">
     <div >
   <div className="bian">
   
                 <table className='tam'>
         <thead>
         <tr>
            <th> Resultat cours de Code</th>                    
              
            
            
            <th>Resultat cours de Conduite </th>  
            <th>Statut</th>  
                            
              </tr>
            </thead>
            <tr> 
              {  
                candidat.map((data, i)=> ( 
                           
                        <td className='th'   data-label='Code'>{data.resultat_code}</td>                                              
                         
                ))
              } 
               {  
                candidat.map((data, i)=> ( 
                           
                        <td className='th'   data-label='Conduite'>{data.resultat_conduite}</td>                                              
                         
                ))
              } 
              <td className="th"   data-label='Statut'>{stat}</td>
              
              </tr> 
     </table>
     <br/><br/>
     </div>
     <div>
     </div>
     
     </div>
     </div>

     </div>

</div> */}
 
 </div>

</div>


        

      
<br/> 


<br/>
<br/>

<br/>

<br/><br/><br/><br/>


      </div>

      </div>





      
        
    </>
)
            }else{
              return(


                <>
                
                <div className="option">
                
                   
              
            <div className="versement">
            
            <br/>
            {((((titre === "moniteur" || titre === "secretaire") && 
              secretaire.some(data => data.access_student_info === 1))) || titre=== "directeur" )  && (
            <h2 className="title">VERSEMENTS</h2>
              )}
            <br/>
              <div className="">
                <div className="">
                  <div className="">
            
                  {effet}
                  {((((titre === "moniteur" || titre === "secretaire") && 
              secretaire.some(data => data.access_student_info === 1))) || titre=== "directeur" )  && (
              
                  <div className="bloom">
                  {
                        
                        candidat.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle"> versement 1</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                          
                             
                             
        {/* Bouton pour générer le PDF */}
       <div className="downI">
             <i class="fas fa-download" onClick={() => generatePDF(
            data.nom,
            data.prenom,
            data.montant_payer,
            data.montant_total,  // Assure-toi que 'total' existe dans les données
            data.date,
            data.reference,  // Si cette donnée existe
            logoUrl,  // Si cette donnée existe
            localisation,
            infoAuto,
            secretaire,
            mail,
            data.categorie,
            data.langue,
            data.dure,
            ifu,
            rcm,
            autorisation,
            data.heure,          
            NB
          )}></i> 
     </div>
                          </div>
                        ))
                      }
                         
                  {
                        
                        payement.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).map((data, i)=>{
                            const sum = payement
                            .filter((p) => new Date(p.id) < new Date(data.id)) 
                            .reduce((acc, p) => acc + Number(p.montant), 0); 

                           return(
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle"> versement {i+2}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                          
                              {/* Bouton pour générer le PDF */}
       <div className="downI">
             <i class="fas fa-download" onClick={() => generatePDF2(
            data.nom,
            data.prenom,
            data.montant,
            total,  // Assure-toi que 'total' existe dans les données
            sum+data.montant+Number(candidat.map((data) => data.montant_payer)),
            data.date,
            data.reference,  // Si cette donnée existe
            logoUrl,  // Si cette donnée existe
            localisation,
            infoAuto,
            mail,
            candidat.map((data) => data.categorie),
            candidat.map((data) => data.langue),
            candidat.map((data) => data.dure),
            ifu,
            rcm,
            autorisation,
            data.heure,
            NB,
            candidat.map((data) => data.montant_payer),
            data.id
          )}></i> 
     </div> 
                          </div>
                        )})
                      }

<div className="omega">
                      <div className="ome">
                    <h3 className='tool'>MONTANT A PAYER </h3>
                    <h4>{total} FCFA</h4>
                    </div>   
                    <div className="ome">
                    <h3 className='tool'>RESTE</h3> 
                    <h4>{calcul-paye} FCFA</h4>
                        
                      </div> 
                    </div>   
                    </div>
              )}

                    </div>
                </div>
                    </div>

                    <br/>
            <br/>

                    <div className="agendeCV">

<div className="presence-list">
  
          {/* Cours de Conduite */}
          <h3>Cours de Conduite</h3>
          {conduiteEvents.length > 0 ? (
            conduiteEvents.map((event, index) => (
              <div key={index} className="presence-item">
                <b>{event.title}</b>
                <div>
                  {moment(event.start).format('dddd DD MMMM YYYY')} à {event.time ? event.time : 'Non spécifié'}
                </div>
              </div>
            ))
          ) : (
            <div>Aucune présence de conduite trouvé.</div>
          )}

          {/* Cours de Code */}
          <h3>Cours de Code</h3>
          {codeEvents.length > 0 ? (
            codeEvents.map((event, index) => (
              <div key={index} className="presence-item">
                <b>{event.title}</b>
                <div>
                  {moment(event.start).format('dddd DD MMMM YYYY')} à {event.time ? event.time : 'Non spécifié'}
                </div>
              </div>
            ))
          ) : (
            <div>Aucune présence de code trouvé.</div>
          )}
        </div>


        </div>
            
            
            <br/>
            <br/>
            <div className="bourse">
            <div className="itec">
           {/*   <div>
            
            <h2 className="title">PRESENCE</h2>
            
            <div className="biou">
             
                 <div>
                
                 <table className='tah'>
                     
                        <tr>
                        <th className="ninh"> Presence cours de Code : {nombre2.length}</th>                    
                          </tr>
                        
                     
                          {  
                            nombre2.map((data, i)=> ( 
                                <tr>        
                                    <td className='tbilan' ><div className="timus">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')} à {data.time}</div></td>                                              
                                </tr>     
                            ))
                          }  
                 </table>
                 </div>
                 <br/>
                 <div>
               
               <table className='tah'>
            
            <tr>
            <th className="ninh">Presence cours de Conduite : {nombre.length}</th>                    
            </tr>
            
            
            {  
              nombre.map((data, i)=> ( 
                  <tr>        
                      <td className='tbilan'><div className="timus">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')} à {data.time}</div></td>                                              
                  </tr>     
              ))
            }  
            </table>
            </div>
                
             </div>
             </div> */}
             
            
             
             </div>
             <div>   
          {/*   <div className="space">
              
            <h2 className="title">RESULTATS</h2>
            <div className="recadre">
                 <div >
               <div className="bian">
               
                             <table className='tam'>
                     <thead>
                     <tr>
                        <th> Resultat cours de Code</th>                    
                          
                        
                        
                        <th>Resultat cours de Conduite </th>  
                        <th>Statut</th>  
                                        
                          </tr>
                        </thead>
                        <tr> 
                          {  
                            candidat.map((data, i)=> ( 
                                       
                                    <td className='th' data-label={data.resultat_code? 'Code':''}>{data.resultat_code}</td>                                              
                                     
                            ))
                          } 
                           {  
                            candidat.map((data, i)=> ( 
                                       
                                    <td className='th' data-label={data.resultat_conduite? 'Conduite':''} >{data.resultat_conduite}</td>                                              
                                     
                            ))
                          } 
                          <td className="th" data-label={stat!=''? 'Statut':''}  >{stat}</td>
                          
                          </tr> 
                 </table>
                 <br/><br/>
                 </div>
                 <div>
                 </div>
                 
                 </div>
                 </div>
            
                 </div> */}
            
            </div>
            </div>
            
            
                    
            
                  
            <br/> 
            
            
            <br/>
            <br/>
            
            <br/>
            
            <br/><br/><br/><br/><br/>
            
            
                  </div>
            
                  </div>
            
            
            
            
            
                  
                    
                </>
            )
            }
}
    
}   




