import React, {useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../components/loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../apiConfig';
function UpdatePayement(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [name, setName]= useState('')
    const [prenom, setPrenom]= useState('')
    const [montant, setMontant]= useState('')
    const [libelle, setLibelle]= useState('')
    const [date, setDate]= useState('')
    const [lapaye, setLapaye]= useState('')
    const [info, setInfo]= useState('')
    const {id}= useParams();
    const [dateControl, setDateControl]= useState('')
    const [sum,setSum]= useState('')
    const [val, setVal]= useState('')
    const [montant_payer, setMontant_payer]= useState('')
    const [montant_total, setMontant_Total]= useState('')
    const [vev, setVev]=useState('')
    const navigate = useNavigate();
    const [loader, setLoader]= useState(false);
    const [idC, setidC]= useState('')
    var date2= new Date();
   var autre= date2.toISOString().split('T')[0]  


   const [candidat, setCandidat] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/updatePayement/'+id, { headers})
        .then(res => {
            setName(res.data[0].nom)
            setPrenom(res.data[0].prenom)
            setMontant(res.data[0].montant)
            setLibelle(res.data[0].libelle)
            setVal(res.data[0].montant)
            setidC(res.data[0].id_candidat)
            setVev(1)
            setDate(moment(res.data[0].date).utc(1).format('YYYY-MM-DD'))
            setCandidat(res.data)})
        .catch(err => console.log(err));
   
    }, [])
   if(vev==1){
    axios.post(API_BASE_URL +'/lapaye3', {idC}, { headers})
    .then(res => {
       setMontant_Total(res.data[0].montant_total)
       setMontant_payer(res.data[0].montant_payer)
   }).catch(err => console.log(err));


axios.post(API_BASE_URL +'/vefMontantDir3', {idC}, { headers})
           .then(res => {
               setSum(res.data[0].sum)
           }).catch(err => console.log(err));
        setVev('')

        }
       
    function handleSubmit(event){
        setInfo('')
        setLapaye('')
        setLoader(true)
        event.preventDefault();
        if( montant=="" || libelle=="" || date=="" || name=="" || prenom=="" || montant<=0){
            setLoader(false)
            setInfo("UN CHAMP EST MAL REMPLI VEILLEZ VERIFIER!!!")
           
         }else if(date>autre){
            setLoader(false)
            setDateControl('Verifier la date')
        }
         else{


            axios.post(API_BASE_URL +'/lapaye', {name,prenom}, { headers})
        .then(res => {
            if(res.data.message){
                setLoader(false)
                setLapaye(res.data.message);
                
                
            }
            else{
                if(sum+ montant_payer< montant_total){
                    if((sum-val)+montant_payer+Number(montant)== montant_total){
                        axios.put(API_BASE_URL +'/solde2/'+idC, { headers})
                        .then(res => {
                         
                        }).catch(err => console.log(err));

                        axios.put(API_BASE_URL +'/updatePayement/'+id, {name,prenom,libelle,date,montant}, { headers})
        .then(res => {
            setLoader(false)
            navigate('/payement');
        }).catch(err => console.log(err));
                    }else if((sum-val)+montant_payer+Number(montant)< montant_total){
                        axios.put(API_BASE_URL +'/updatePayement/'+id, {name,prenom,libelle,date,montant}, { headers})
        .then(res => {
            setLoader(false)
            navigate('/payement');
        }).catch(err => console.log(err));
                    }else if((Number(montant)+sum+montant_payer)-val> montant_total){
                        setLoader(false)
                        setInfo('ATTENTION LE MONTANT EST SUPERIEUR AU RESTE A PAYERrr!!!')
                    }
                
                
            }else if(sum+montant_payer== montant_total){
                if(Number(montant)== val){
                    axios.put(API_BASE_URL +'/updatePayement/'+id, {name,prenom,libelle,date,montant}, { headers})
                    .then(res => {
                        setLoader(false)
                        navigate('/payement');
                    }).catch(err => console.log(err));
                }else if ((Number(montant)+sum+montant_payer)-val> montant_total){
                    setLoader(false)
                    setInfo('ATTENTION LE MONTANT EST SUPERIEUR AU RESTE A PAYER!!!')
                }else {
                    axios.put(API_BASE_URL +'/desolde/'+idC, { headers})
                    .then(res => {
                    
                        
                    }).catch(err => console.log(err));

                    axios.put(API_BASE_URL +'/updatePayement/'+id, {name,prenom,libelle,date,montant}, { headers})
                    .then(res => {
                        setLoader(false)
                        navigate('/payement');
                    }).catch(err => console.log(err));
                }
                
            }
        }
     
           
            
        }).catch(err => console.log(err));
       
         }
         
    }

    
    const handleGoBack = () => {
        navigate('/payement');
      };
  
    let load

    if(loader==true){
        load=   <div className="modal">
            <div className="overlay">

            </div>
               <div className="modal-contentis">
    <div className="anous">
    <Loader/>
    </div>
    </div>
        </div>
       
    }else{
        load=''
    }
     
    
   

    return(
        <div className="auths"> 
        <body className="body">
     <div className="container">
     <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
     <h2 className="form-title">Mise à d'un paiement</h2>
             <form onSubmit={handleSubmit} action="#">
             
             <div className="main-user-info">
              <div className="user-input-box">
              <label htmlFor="fullname" className="">Nom</label>
                
                <input type="text" name="fullName" className="upo" id="fullName" value={name}  onChange={e => setName(e.target.value)}  ></input>
               
                </div> 
              
             <div className="user-input-box">
             <label htmlFor="username" className="">Prenom</label>
                <input type="text" name="fullName" className="upo" id="fullName" value={prenom}  onChange={e => setPrenom(e.target.value)}></input>
                
                </div>   
                
                <div className="user-input-box">
                <label htmlFor="date" className="">DATE</label>
                <h4 className="text-danger">{dateControl}</h4>
                <input type="date" name="date" className="" value={date}  id="date" onChange={e => setDate(e.target.value)}  ></input>
               
                </div>
                
                <div className="user-input-box">
                <label htmlFor="montant" className="">Montant</label>
                <input type="number" name="montant" className="" id="montant" value={montant} onChange={e => setMontant(e.target.value)}></input>
                
                </div>
                <div className="libe">
                <label htmlFor="libelle" className="">Libelle</label>
                <input type="text" name="libelle" className="" id="libelle" maxLength={100} value={libelle} onChange={e => setLibelle(e.target.value)}></input>
             
                </div>
                
                </div>
                <h3 className="text-danger">{lapaye}</h3><br/>
                <h4 className="text-danger">{info}</h4>
                <div className="form-submit-btn">
                <input type="submit" className='' value="ENREGISTRER" ></input>
                
                </div>
                

             </form>
        </div> 
        </body>
        {load}
        </div>

    )
}
export default UpdatePayement



