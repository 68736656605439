import React, {useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/loader";
import { API_BASE_URL } from '../apiConfig';
function Observation(){
    const [name, setName]= useState('')
    const [prenom, setPrenom]= useState('')
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [observation, setObservation]= useState('')
    
    const {id}= useParams();
    
    const navigate = useNavigate();

    function handleSubmit(event){
        event.preventDefault();
        axios.put(API_BASE_URL +'/observation/'+id, {name,prenom,observation}, { headers})
        .then(res => {
            navigate('/candidatMoni');
        }).catch(err => console.log(err));
    }
    

    const [candidat, setCandidat] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/observation/'+id, { headers})
        .then(res => { 
            setObservation(res.data[0].observation)
            setCandidat(res.data)})
        .catch(err => console.log(err));
    }, [])

    



    return(
        <div className="auths">
            
            <body className="body">
            
            <div className="container">
            <h1 className="form-title">Observation</h1>
                 <form action="#" onSubmit={handleSubmit}>
                    
                 <div className="main-user-info">
                   <div className="user-input-box">
                   <label htmlFor="nom" className="">Nom</label>
                   <input type="text" className="" name="nom"  id="nom" value={candidat.map((data)=>(data.nom))} onChange={e => setName(e.target.value)}  ></input>
                   </div>
                    
                    <div className="user-input-box">
                    <label htmlFor="prenom" className="">Prenom</label>
                    <input type="text" name="prenom" className="" id="prenom" value={candidat.map((data)=>(data.prenom))} onChange={e => setPrenom(e.target.value)}></input>
                    
                    </div>
            
                    <div className="serve">
                    <label htmlFor="contact" className=""  >Observation</label>
                    <textarea cols={100} rows={3} className="" value={observation} onChange={e => setObservation(e.target.value)}></textarea>
                   
                    </div>
                    
                    
                     
                    </div>

                    <div className="form-submit-btn">
                    <input type="submit" className='' value="ENREGISTRER" ></input>
                    
                    </div>
                    
                 </form>
            </div> 
            </body>
        </div>
    )
}
export default Observation