import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from '../components/navbar';
import Loader from "../components/loader";
import { Link } from "react-router-dom";
import Footer from '../components/footer';
import Cookies from 'js-cookie';
import moni from '../components/image/moni.png';
import secre from '../components/image/images.png'
import { API_BASE_URL } from '../apiConfig';
export default function MoniResult(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };    
    var date= new Date();
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
    const[value,setValue]= useState('')
    const days= date.getDate()
var  month=  date.getMonth()+1
var autre= date.toISOString().split('T')[0] 
var datee= new Date(years,month-3,days)
var autre2= datee.toISOString().split('T')[0]
const [date1, setDate1]= useState(autre2)
    const [dat, setDat]= useState('')
  const [date2, setDate2]= useState(autre)
  const [titre,setTitre]= useState(Cookies.get('titre'))
  const [vtoken, setVtoken]= useState(Cookies.get('token'))
  const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [candidat, setCandidat] = useState([])
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const [mie, setMie]= useState(Cookies.get('mie'))
    const [viewLoader, setViewLoader] = useState(false)
    const [error, setError] = useState(null);
    const [loader, setLoader]= useState(true)




    const [query, setQuery]= useState(""); 
 
    useEffect(()=>{
        axios.post(API_BASE_URL +'/intervalle', {date1,date2,gestauto,mie}, { headers})
        .then(res => {
            setCandidat(res.data)
            setLoader(false)
        })
        .catch(err => {
            console.log(err)
           setError(err)
           setLoader(false)
        });
    }, [])


     

         function handleTout(event){
            setViewLoader(true)
            event.preventDefault();
            if(value=='Enregistrer ce mois'){
                axios.post(API_BASE_URL +'/candidatThisMoni',{month,years,mie}, { headers})
                .then(res =>{
                     setCandidat(res.data)
                     setViewLoader(false)
                    
                    })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                     setViewLoader(false)
                    
                    });
                }
            else if(value=='Enregistrer cette année'){
                axios.post(API_BASE_URL +'/candidatThisYears',{years,mie}, { headers})
                .then(res =>{
                     setCandidat(res.data)
                     setViewLoader(false)
                    })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                     setViewLoader(false)
                    });
            }else if(value=='Tout les Candidat'){
                axios.get(API_BASE_URL +'/candidatMoni?id='+mie+'', { headers})
                .then(res => setCandidat(res.data))
                .catch(err => console.log(err));
            }else if(value=='Qui ont retirer leurs permis'){
                axios.post(API_BASE_URL +'/candidatPermis',{mie}, { headers})
                .then(res => setCandidat(res.data))
                .catch(err => console.log(err));
            }else if(value=='Les deux derniers mois'){
                axios.post(API_BASE_URL +'/intervalle', {date1,date2,gestauto,mie}, { headers})
                .then(res => setCandidat(res.data))
                .catch(err => console.log(err));
            }
           
        }

        const mettreAJourLargeurEcran = () => {
            setLargeurEcran(window.innerWidth);
        
          };
        
           
           
            
            
            useEffect(() => {      
                window.addEventListener('resize', mettreAJourLargeurEcran);      
                return () => {
                  window.removeEventListener('resize', mettreAJourLargeurEcran);        
                };      
              }, []);

        const [id, setId] = useState('')
        const [id2, setId2]= useState('')
    const [secretaire, setSecretaire] = useState([])

    useEffect(()=>{
            axios.post(API_BASE_URL +'/avoir', {gestauto, titre}, { headers})
            .then(res =>{
                 setId(res.data[0].id_ecole)
                setId2(res.data[0].id_ecole)
                })
            .catch(err => console.log(err));
    
        }, [])
    function handlePropose(event){
        setViewLoader(true)
        event.preventDefault();
        axios.post(API_BASE_URL +'/modfDate', {date1,date2,id2}, { headers})
        .then(res => {
           setCandidat(res.data)
           setViewLoader(false)
        }).catch(err =>{
             console.log(err)
             setError(err)
             setViewLoader(false)
            });
    }

    let load

    if(viewLoader==true){
        load=   <div className="avt">
        <div className="rin">
          <div className="chm">
            <Loader/>
          </div>
        </div>
      </div>
    }else{
        load=''
    }

    if(loader){
        return(
            <div className="per">
            
            <div className="loader">
            <div className="overlayLo"> 
                <div className="loaderP">
                <Loader/>
                </div>
                </div>
                </div>
                </div>
        )
    }else if(error){
        return (<div>Une erreur est survenue : {error.message}</div>)
    }
    else{

    if(largeurEcran>= 510){

    return(
        <>
             
             <div className='wini'>
        <Navbar/>
        <h1 className='win'>La page de gestion des resultats </h1><br/>
        {load}
        <div className='inove'>
        <div className="search">
             <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
        </div>
        <div className='selvil'  >
             
             
             
           
           <div className='person2'>
           <input type='date' value={date1} className='oix2' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oix2' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='somi2'>APPLIQUER</button>
           
           </div>
           </div>  
        </div>
        <br/><br/>

    <div className=''>
        <div className=''>
             
             <table className='ta'>
                 <thead >
                    <tr>
                    
                    <th>Nom & Prenom</th>
                    
                   
                    
                    <th>Code</th>
                    <th>Conduite</th>
                    
                    
                    </tr>
                 </thead>
                 <tbody>
                      {
                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                            <tr >
                                
                                <td className='tcond'><Link to={'/pageCandidat/'+ data.id} className='lin'>{data.nom} {data.prenom}</Link></td>
                                
                                
                               
                                <td className='tcond'>{data.activeCode ? 'admis' : ''}</td>
                                <td className='tcond'>{data.activeConduite ? 'admis' : ''}</td>
                              
                                           
                              
                                
                            </tr>
                        ))
                      }
                 </tbody>
             </table>
        </div>
        
    </div>
    <br/>
    </div>
<Footer/>
        </>
    )
                    }else{
                        return(
                            <>
                                 
                                 <div className='wini'>
                            <Navbar/>
                           {load}
                            <div className='barContener'>
                
                {/* Barre de recherche */}
                <div className='barChercheT'>
                    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
                </div>
                
                
                {/* Barre de recherche personnalisée */}
                <div className='persBarT'>
                    
                    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
                </div>
                
                </div>
                
                    <br/><br/><br/>
                    <div className="card-list">
                             {
                                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                                            <div className="card">
                                                       <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                                         <div className='info'>
                                            <p className='name'> {data.nom} {data.prenom}</p>
                                         
                                            {/* <p className='contacts'>Note Code: {data.resultat_code} </p>
                                            <p className='contacts'>Note Conduite: {data.resultat_conduite} </p> */}
                                            <p className='contacts'>Code : {data.activeCode ? 'admis' : ''}</p>
                                            <p className='contacts'>Conduite : {data.activeConduite ? 'admis' : ''}</p>
                                            </div>
                                        </div>
                                    
                                        ))
                                      } 
                             </div>
                   
                        </div>
                    <Footer/>
                            </>
                        )
  
                    }
                }
}