import Navbar from '../components/navbar';
import { Link } from "react-router-dom";
import Footer from '../components/footer';
import Loader from "../components/loader";
export default function Presence() {
    return(
      <>  
    <div className="wini">
        <Navbar/>
         <h1 className='win'>Gestion des présences</h1>
         <div className='ope'>
         <Link to={'/cond'}>     <button className='pre'> PRESENCE CONDUITE</button></Link>
         <Link to={'/code'}>       <button  className='cod'>PRESENCE CODE</button></Link>
                                    </div>
                                    <br/>                                
    </div>
    <Footer/>
    </>
    )
}