import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../apiConfig';
export default function ToggleSwitch({ label, activeCode, candidatId, onToggle,date_code }) {
    // Initialiser l'état avec la valeur de activeCode passée en props (vrai ou faux)
    const [isChecked, setIsChecked] = useState(activeCode || false);

    // Fonction pour gérer le changement d'état du toggle
    const handleToggle = async () => {
        const newCheckedStatus = !isChecked;
        setIsChecked(newCheckedStatus); // Mettre à jour l'état local

        // Appel API pour mettre à jour la base de données
        try {
            await axios.post(API_BASE_URL +'/update-active-code', {
                id: candidatId,
                activeCode: newCheckedStatus,  // Mettre à jour la base avec le nouveau statut
                date_code: date_code,
            });
            
            if (onToggle) onToggle(newCheckedStatus);  // Notifier le parent en cas de changement
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut :", error);
            // Optionnel : Revenir à l'état précédent en cas d'erreur
            setIsChecked(!newCheckedStatus);
        }
    };

    return (
        <div className="toggle-container">
            <label className="toggle-switch">
                <input 
                    type="checkbox" 
                    checked={isChecked} 
                    onChange={handleToggle} 
                />
                <span className="slider"></span>
            </label>
        </div>
    );
}