import React, {useEffect, useState } from "react"
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import { API_BASE_URL } from '../apiConfig';
export default function Versement(){

         
    
         const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
            'credentials': 'include',
          };
         const [le, setLe]= useState('')
         const [name, setName]= useState('')
         const [prenom, setPrenom]= useState('')
         const [total, setTotal]= useState('')
         const [contact, setContact]= useState('')
         const [sexe, setSexe]= useState('')
         const [adresse, setAdresse]= useState('')
         const [age,setAge]= useState('')
         const [montant, setMontant]= useState('')
         const [statut, setStatut]= useState('')
         const [date, setDate]= useState('')
         const [nombre, setNombre]= useState('')
         const [nombre2, setNombre2]= useState('')
         const {id}= useParams();
         const [loginStatut, setLoginStatut]= useState('')
         const navigate = useNavigate();
         const [payement, setPayement]= useState([])
         
         
        
     
         
              
         
         
         
     
     
     
         const [candidat, setCandidat] = useState([])
         useEffect(()=>{
             axios.get(API_BASE_URL +'/update/'+id, { headers})
             .then(res => { 
                 setLe(res.data[0].id)
                 setName(res.data[0].nom)
                 setPrenom(res.data[0].prenom)
                 setContact(res.data[0].contact)
                 setSexe(res.data[0].sexe)
                 setAdresse(res.data[0].adresse)
                 setAge(res.data[0].age)
                 setMontant(res.data[0].montant_payer)
                 setStatut(res.data[0].statut_payement)
                 setDate(res.data[0].date)
                 setCandidat(res.data)
                 setTotal(res.data[0].montant_total)
             
             }
                 
                 
                 
            
             
                
     
     
                 
                 )
             .catch(err => console.log(err));
     
         
         }, [])
         
         axios.post(API_BASE_URL +'/paye',{name,prenom}, { headers})
         .then(res => setPayement(res.data)
     ).catch(err => console.log(err))
         
     let totalPaye=0
     const first=montant
     
     
     
     payement.map((data, i)=> ( 
                     
         totalPaye=totalPaye+data.montant    
     
     ))
         const paye= first+totalPaye
         
        const calcul= total 
     
     
        
        axios.post(API_BASE_URL +'/nombre',{le}, { headers})
        .then(res => setNombre(res.data)
     ).catch(err => console.log(err))
       
     axios.post(API_BASE_URL +'/nombre2',{le}, { headers})
        .then(res => setNombre2(res.data)
     ).catch(err => console.log(err))









    
    return(


        <>
    
      

      <Link to={'/pageCandidatPaye/'+le} className='linko'> Nouveau Payement </Link>
      <table className='ta'>
             <thead >
                    <tr>
                    
                    <th >Versement</th>
                    <th >Libelle</th>
                    <th >Montant</th>
                    <th >Date</th>
                   
                    </tr>
                 </thead>
                
               <tbody>
                      {
                        candidat.map((data, i)=> (
                            <tr>
                                <td className='th'> 1 </td>
                                <td className='th'>INSCRIPTION</td>
                                
                                <td className='th'>{data.montant_payer}</td>
                                <td className='th'>{moment(data.date).utc(1).format('DD-MM-YYYY')}</td>
                                
                                
                                
                            </tr>
                        ))
                      } 

                       {
                       
                       payement.map((data, i)=> (
                        <tr >
                            <td className='th'> {i+2} </td>
                            <td className='th'> {data.libelle}</td>
                            <td className='th'>  {data.montant}</td>
                             <td className='th'> {moment(data.date).utc(1).format('DD-MM-YYYY')}</td>
                            
                           
                            
                            
                        </tr>

                    )) 

                    
                      } 
                     
                 </tbody> 
             </table>
             <table className='ta'>
             <tr>
         <td className="" ><h3 className='totale'>SOMME DES VERSEMENTS</h3></td>
            
            <td className=''><h4>{first+totalPaye}</h4></td>
         </tr>
         <tr>
         <td className="" ><h3 className='totale'>MONTANT A PAYER</h3></td>
            
            <td className=''><h4>{total}</h4></td>
         </tr>
         <tr>
         <td className="" ><h3 className='totale'>RESTE A PAYER</h3></td>
            
            <td className=''><h4>{calcul-paye}</h4></td>
         </tr>
         </table>
        












      
        </>
    )
}