
import axios from "axios";
import React, {useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import jsPDF from 'jspdf';
import moment from "moment";
import { Link, NavLink } from "react-router-dom";
import 'moment/locale/fr';
import 'jspdf-autotable';
import { API_BASE_URL } from '../apiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
export default function AdmisDir() {
    const {id}= useParams();
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
      var date= new Date();
      const navigate = useNavigate();
      const years= date.getFullYear()
      var  month=  date.getMonth()+1
      const days= date.getDate()
      var datee= new Date(years,month-3,days)
      const [lesAdm, setLesAdm] = useState([])
      const [date1, setDate1]= useState('')
      const [date2, setDate2]= useState('')
      const [select1,setSelect1]= useState(Cookies.get('select1'))
      const [select2,setSelect2]= useState(Cookies.get('select2'))
      const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
     
      const [loader, setLoader]= useState(false);
      const [error, setError] = useState(null);
   
      var autre2= datee.toISOString().split('T')[0] 
      var autre= date.toISOString().split('T')[0]


       
 useEffect(()=>{
    setLoader(true)
    if( select1 === undefined || select2== undefined){ 
    setDate1(autre2)
    setDate2(autre)
   }else if (select1 || select2){
     setDate1(select1)
     setDate2(select2)
   }


  }, [])
     
      

      useEffect(()=>{
        

        axios.post(API_BASE_URL +'/affAdm', {date1,date2,id},{headers})
      .then(res =>{ 
        if (Array.isArray(res.data)) {
            setLesAdm(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
          } else {
            console.error("Les données reçues ne sont pas un tableau : ", res.data);
            setLesAdm([]);  // Définit un tableau vide si ce n'est pas un tableau
          }
        console.log('entout cas moi je suis là ', res.data)
        setLoader(false)
   
      }
      ).catch(err => {
        console.log(err)
      setError(err)
      setLoader(false)
    });
    }, [date1,date2,id])
   
    let load

    if(loader==true){
        load=   <div className="modal">
            <div className="overlay">

            </div>
               <div className="modal-contentis">
    <div className="anous">
    <Loader/>
    </div>
    </div>
        </div>
       
    }else{
        load=''
    }

    if(error){
        return (<div>Une erreur est survenue : {error.message}</div>)
    }else{
    return(
        
    <div className="authes">
        <br/> <br/> <br/>
         
        <body className="body">

        <div className="overlay"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
        <h3 className="titre-modal">Liste des admis</h3>
      </div>
      <div className="modal-corps">
        <table className="table-inscrits">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
            </tr>
          </thead>
          <tbody>
            {lesAdm.reverse().map((data, i) => (
              <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
                <td><Link to={'/pageCandidatDir/'+ data.id} className='bnfs'>{data.nom}</Link></td>
                <td><Link to={'/pageCandidatDir/'+ data.id} className='bnfs'>{data.prenom}</Link></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="boutons-modal">
        <Link to={'/accueil2/'+id}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
     </body>

    </div>
    )
}
}